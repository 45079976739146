import React from "react";
import Chart from 'react-apexcharts';


function splitArrays(data) {
    const labels = data.map(item => item.name);
    const values = data.map(item => item.bidsToday);

    return {labels, values};
}

const template = {
    labels: [ 'Joe Biden', 'Donald Trump', 'Barack Obama', 'Thomas Jefferson', 'George Washington' ],
    values: [ 2021, 2017, 2009, 1801, 1732 ]
}



const Pie = ({ data }) => {
    const formatData =  data ? splitArrays(data) : template;

    var chartOptions = {
        chart: {
          type: 'pie',
          width: 100
        },
        labels: formatData.labels,
        legend: {
            onItemHover: {
              highlightDataSeries: true
            },
            labels: {
                // colors: "white",
                useSeriesColors: true
            }
          },

      };
      
      // Now you can use the variable chartOptions wherever you need the chart options in your code.
      

  return  <Chart options={chartOptions} series={formatData.values} type="pie" width="450" />;
};

export default Pie;
