import Box from "@mui/material/Box";
import { default as MCard } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Search from "../../../components/Search/Input";
import Button from "../../../components/Search/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Select from "../../../components/Select/Select";
import months from "../../../configs/months";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";

export default function SearchCard({
	data,
	loading,
	user,
	setUser,
	month,
	setMonth,
	setWage,
	setPercentage,
	support,
	setSupport,
	handleSearch,
}) {
	console.log(data);

	return (
		<Box
			sx={{
				marginTop: "1.5rem",
			}}
		>
			<MCard variant="elevation">
				<CardContent>
					<Typography
						align="left"
						variant="h5"
						gutterBottom
					>
						Options
					</Typography>
					<Box
						component="form"
						onSubmit={handleSearch}
						noValidate
						sx={{ mt: 1 }}
					>
						<Grid
							container
							rowSpacing={1}
							spacing={2}
							sx={{ userSelect: "none", mt: 2 }}
						>
							<Grid
								item
								xs={2}
							>
								<Select
									sx={{ m: 1 }}
									title={"User"}
									options={data}
									selectedOptions={user}
									setSelectedOptions={setUser}
								/>
							</Grid>
							<Grid
								item
								xs={2}
							>
								<Select
									sx={{ m: 1 }}
									title={"Month"}
									options={months}
									selectedOptions={month}
									setSelectedOptions={setMonth}
								/>
							</Grid>
							<Grid
								item
								xs={2}
							>
								<Search
									setSearchTerm={setWage}
									label={"Fix wage "}
								/>
							</Grid>
							<Grid
								item
								xs={2}
							>
								<Search
									setSearchTerm={setPercentage}
									label={"Percentage"}
								/>
							</Grid>
							<Grid
								item
								xs={1}
							>
								<FormGroup>
									<FormControlLabel
										label="Support"
										labelPlacement="start"
										sx={{ ml: 0 }}
										control={
											<Checkbox
												onChange={(e) => setSupport(e.target.checked)}
												checked={support}
												sx={{ "& .MuiSvgIcon-root": { fontSize: 30, ml: 1 } }}
											/>
										}
									/>
								</FormGroup>
							</Grid>
							<Grid
								item
								xs={3}
							>
								<Button
									loading={loading}
									title="Download"
								/>
							</Grid>
						</Grid>
					</Box>
				</CardContent>
			</MCard>
		</Box>
	);
}
