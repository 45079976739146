import Box from "@mui/material/Box";
import { default as MCard } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Search from "../../../components/Search/Input";
import Button from "../../../components/Search/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function SearchCard({ loading, setMc, setCompany, handleSearch }) {
  return (
    <Box
      sx={{
        marginTop: "1.5rem",
      }}
    >
      <MCard variant="elevation">
        <CardContent>
          <Typography align="left" variant="h5" gutterBottom>
            Options
          </Typography>
          <Box component="form" onSubmit={handleSearch} noValidate sx={{ mt: 1 }}>
            <Grid container rowSpacing={1} spacing={2} sx={{ userSelect: "none", mt: 2 }}>
              <Grid item xs={5}>
                <Search setSearchTerm={setMc} label={"MC №"} />
              </Grid>
              <Grid item xs={5}>
                <Search setSearchTerm={setCompany} label={"Company Name"} />
              </Grid>
              <Grid item xs={2}>
                <Button loading={loading} />
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </MCard>
    </Box>
  );
}
