import { memo } from "react";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";


const MemoizedListItem = memo(function ListItemComponent({element, open, pathname}) {
    return (
      <ListItem
        key={element.key}
        disablePadding
        sx={{
          display: "block",
          color: element.to === pathname ? "#3498ff" : "",
        }}
      >
        <Tooltip title={!open ? element.title : ""} placement="right" arrow>
          <span>
          <ListItemButton
            component={Link}
            to={element.to}
            selected={element.to === pathname}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
                fontSize: "20px",
                color: element.to === pathname ? "#3498ff" : "",
              }}
            >
              {element.icon}
            </ListItemIcon>
            <ListItemText primary={element.title} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
          </span>
        </Tooltip>
      </ListItem>
    );
  });



export default MemoizedListItem