import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import navigationItems from "../configs/navigations";

const DEFAULT_TITLE = "Speed Loaders";

function TitleUpdater() {
  const { pathname } = useLocation();
  const { controllers } = global;

  useEffect(() => {
    const matchingElement = navigationItems.find(item => item.to === pathname);
    const title = matchingElement ? matchingElement.title : DEFAULT_TITLE;
    document.title = title;

    Object.keys(controllers)?.forEach((controller, index) => {
      if (controller !== pathname) {
        controllers[controller]?.forEach(element => {
          element.abort();
        });

        controllers[controller] = []
      }
    });
  }, [pathname, controllers]);

  return null;
}

export default TitleUpdater;
