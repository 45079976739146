const TAX_PERCENTAGE = 0.03;
const DISPATCHER_PERCENTAGE = 0.1;
const THRESHOLD_VALUE = 950;
const INCREMENT = 5;
const MINIMUM_BALANCE = 50;

export default function calculateInvoicePrice(driverPrice, type) {
  const parsedDriverPrice = parseInt(driverPrice);

  

  let totalAmount = applyIncrement(parsedDriverPrice);
  let balance = computeBalance(totalAmount, parsedDriverPrice);

  while (balance <= MINIMUM_BALANCE) {
    totalAmount += INCREMENT;
    balance = computeBalance(totalAmount, parsedDriverPrice);
  }

  switch (type) {
    case "Invoice":
      return Math.round(totalAmount);
    case "Balance":
      return Math.floor(balance);
    case "Interest":
      return Math.floor(calculateDispatcherPrice(totalAmount, parsedDriverPrice));
    default:
      return undefined; // or handle invalid type in some other way
  }
}

function applyIncrement(amount) {
  const increment = amount <= THRESHOLD_VALUE ? 50 : amount * 0.1;
  return amount + increment;
}

function computeBalance(totalAmount, driverPrice) {
  const invoiceWithoutTax = totalAmount - totalAmount * TAX_PERCENTAGE;
  const dispatcherPrice = calculateDispatcherPrice(totalAmount, driverPrice);
  return invoiceWithoutTax - driverPrice - dispatcherPrice;
}

function calculateDispatcherPrice(totalAmount, driverPrice) {
  return (totalAmount - driverPrice) * DISPATCHER_PERCENTAGE;
}
