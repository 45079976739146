import { Card, CardContent, Typography, Grid, TextField, InputAdornment } from "@mui/material";
import { useState } from "react";
import calculateInvoicePrice from "../../../utils/calculateInvoicePrice";

const Calculator = () => {
  const [balance, setBalance] = useState("");

  const renderTextField = ({label, value, readOnly = false, placeholder}) => (
    <Grid item xs={3}>
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        placeholder={placeholder}
        inputProps={{ readOnly, min: 0, }}
        size="small"
        type="number"
        value={readOnly ? (isNaN(value) ? "" : value) : balance}
        onChange={
          !readOnly
            ? (e) => {
                setBalance(e.target.value);
              }
            : undefined
        }
        fullWidth={true}
        label={label}
        variant="outlined"
      />
    </Grid>
  );

  return (
    <Card sx={{ minWidth: "100%", minHeight: "100%", mt: "1rem" }}>
      <CardContent>
        <Typography align="left" variant="h5" gutterBottom>
          Check Balance
        </Typography>
        <Grid container rowSpacing={2} spacing={3} mt={1} sx={{ userSelect: "none" }}>
          {renderTextField({label: "Driver's rate",     placeholder: "0"})}
          {renderTextField({label: "Minimum Rate",      placeholder: "0", value: calculateInvoicePrice(balance, "Invoice"),   readOnly: true})}
          {renderTextField({label: "Balance",           placeholder: "0", value: calculateInvoicePrice(balance, "Balance"),   readOnly: true})}
          {renderTextField({label: "Dispatch Interest", placeholder: "0", value: calculateInvoicePrice(balance, "Interest"),  readOnly: true})}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Calculator;
