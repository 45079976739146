import { Typography, Tooltip, Button } from "@mui/material";
import formatDate from "../../utils/formatDate";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ArticleIcon from '@mui/icons-material/Article';
import copySignature from "../../utils/copySignature";
const Row = ({ data, setOpen, setModalData, loading }) => {
	const rows = data.map((item, index) => ({
		id: item._id,
		distance: item.distance,
		Unit: item.Unit,
		Name: item.Name,
		Available: item,
		Type: item.TYPE,
		DimensionsPayload: (
			<Typography>
				{" "}
				{item.dims} <br /> {item.payload}
			</Typography>
		),
		UpdatedByDate: (
			<Typography>
				{" "}
				{item.updatedBy} <br /> {formatDate(item.updatedDate)}
			</Typography>
		),
		Notes: item.notes || "None",
		BidNotes: item.bidNotes || "None",
		Actions: (
			<div style={{display: "flex", gap: 10}}>
				<Tooltip
					placement="top"
					arrow
					title="Copy Signature"
				>
					<span>
						<Button
							disabled={loading}
							variant="outlined"
							size="large"
							onClick={() => {
								copySignature(item)
							}}
						>
							<ArticleIcon />
						</Button>
					</span>
				</Tooltip>
				<Tooltip
					placement="top"
					arrow
					title="Edit Unit"
				>
					<span>
						<Button
							disabled={loading}
							variant="outlined"
							size="large"
							onClick={() => {
								setModalData(item);
								setOpen(true);
							}}
						>
							<ManageAccountsIcon />
						</Button>
					</span>
				</Tooltip>
			</div>
		),
	}));

	return rows;
};

export default Row;
