// Main
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useWebSocket from "react-use-websocket";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useEffect } from "react";

// Utils
import TitleUpdater from "./utils/titleUpdater";
import Token from "./utils/token";
import WebSocket from "./utils/websocket";
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Step 3: Import service worker registration

// Routes
import Home from "./pages/Home";
import Loads from "./pages/Loads";
import Main from "./pages/Main";
import Vehicles from "./pages/Vehicles";
import ColoredAreas from "./pages/Colored Areas";
import CheckBroker from "./pages/Check Broker";
import ClosestUnits from "./pages/Closest Units";
import FindLoad from "./pages/Find Load";
import History from "./pages/History";
import SignIn from "./pages/Login";
import Invoices from "./pages/Invoices";
import Payslip from "./pages/Payslip";
import Users from "./pages/Users";
import { LicenseInfo } from '@mui/x-license';

function App() {
  const { token } = Token();
  const { lastJsonMessage } = useWebSocket((process.env.REACT_APP_DEBUG === "true" ? "ws://localhost:4444/" : "wss://api.speedloaders.xyz/websocket/"), {
    onOpen: () => console.log("Establised a connection to WS"),
    shouldReconnect: (closeEvent) => (closeEvent.code === 1005 ? false : token ? true : false),
    queryParams: {
      token: token,
    },
  });

  // Step 3: Add useEffect to register service worker and check for updates
  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        if (window.confirm("New version available. Do you want to refresh the page to apply the update?")) {
          if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            window.location.reload();
          }
        }
      }
    });
  }, []);

  LicenseInfo.setLicenseKey('225515502e8a5fc18f2a3d4a4d554a654c2f61516756714161764f3d4142433132332c453d31363439343833333433332c532c5052454d494d2c4c4d3d616e6e75616c2c4b563d32');

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider
          theme={createTheme({
            palette: {
              mode: "dark",
            },
          })}
        >
          <TitleUpdater />
          <ToastContainer newestOnTop />
          <WebSocket lastJsonMessage={lastJsonMessage} />
          {token ? (
            <Routes>
              <Route path="/" element={<Main />}>
                <Route index element={<Home />}></Route>
                <Route path="loads" element={<Loads />}></Route>
                <Route path="coloredStates" element={<ColoredAreas />}></Route>
                <Route path="vehicles" element={<Vehicles lastJsonMessage={lastJsonMessage} />}></Route>
                <Route path="checkBroker" element={<CheckBroker />}></Route>
                <Route path="closestUnits" element={<ClosestUnits />}></Route>
                <Route path="findLoad" element={<FindLoad />}></Route>
                <Route path="history" element={<History />}></Route>
                <Route path="invoices" element={<Invoices lastJsonMessage={lastJsonMessage} />}></Route>
                <Route path="payslip" element={<Payslip />}></Route>
                <Route path="users" element={<Users />}></Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Route>
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<SignIn />}></Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}
        </ThemeProvider>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
