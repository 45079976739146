import Box from "@mui/material/Box";
import { default as MCard } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styles from "./Card.module.css";

export default function Card({ title, value, color, iconPerformance }) {
  const IconPerformance = iconPerformance;

  return (
    <Box
      sx={{
        marginTop: "1.5rem",
        transition: "transform 0.3s, box-shadow 0.3s, background 0.3s ease",
        "&:hover": {
          boxShadow: `0 0px 5px white`,
          transform: "scale(1.1)",
          backgroundPosition: "100% 0",
        },
      }}
    >
      <MCard variant="elevation">
        <Box className={styles.boxicon} sx={{ background: color }}>
          <IconPerformance sx={{ fontSize: "40px", color: "white" }} />
        </Box>
        <CardContent>
          <Typography align="right" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {title}
          </Typography>
          <Typography align="right" variant="h5" component="div" sx={{ fontWeight: "bold" }}>
            {value}
          </Typography>
        </CardContent>
      </MCard>
    </Box>
  );
}
