import { useState, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Slide,
  Grid,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import formatDate from "../../utils/formatDate";
import RemoveIcon from "@mui/icons-material/Remove";
import PublishIcon from "@mui/icons-material/Publish";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import SelectComponent from "../Select/Select";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialogTitle-root": {
    position: "relative",
    padding: theme.spacing(2),
    "& .MuiIconButton-root": {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  },
}));

function BootstrapDialogTitle({ children, onClose, ...other }) {
  return (
    <DialogTitle {...other}>
      {children}
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
};

function EditModal({ data, open, setOpen, handleSubmit, handleDelete }) {
  const [changedFormValues, setChangedFormValues] = useState({});
  const [dateState, setDateState] = useState(false);

  const handleChange = (key, value) => {
    setChangedFormValues((prevChangedValues) => ({
      ...prevChangedValues,
      [key]: value,
    }));
  };

  const handleClose = () => {
    setChangedFormValues({});
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        PaperProps={{
          variant: "outlined",
          elevation: 0,
        }}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Invoice {data.index}-{data.unit} last edited by {data.lastChangedBy} on {formatDate(data.lastChangedDate)}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2} sx={{ userSelect: "none" }}>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.7rem"} mt={1}>
                Index
              </Typography>
              <TextField
                onChange={(e) => handleChange("index", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.index}
                size="small"
              />
            </Grid>
      

            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"4.6rem"} mt={1}>
                Date won
              </Typography>
              <DateTimePicker
                open={dateState}
                onChange={(value) => handleChange("created", dayjs(value).unix())}
                onClose={() => setDateState(false)}
                slotProps={{ textField: { size: "small", onClick: () => setDateState(true) } }}
                ampm={false}
                value={changedFormValues.created ? dayjs.unix(changedFormValues.created) : dayjs.unix(data.created)}
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.7rem"} mt={1}>
                Unit
              </Typography>
              <TextField
                onChange={(e) => handleChange("unit", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.unit}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5rem"} mt={1}>
                Reference
              </Typography>
              <TextField
                onChange={(e) => handleChange("reference", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.reference}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
              <Typography width={"5rem"} mt={1}>
                Customer
              </Typography>
              <TextField
                onChange={(e) => handleChange("customer", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.customer}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.7rem"} mt={1}>
                Week
              </Typography>
              <TextField
                onChange={(e) => handleChange("weekNumber", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.weekNumber}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5rem"} mt={1}>
                PU Date
              </Typography>
              <TextField
                onChange={(e) => handleChange("puDate", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.puDate}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.7rem"} mt={1}>
                From
              </Typography>
              <TextField
                onChange={(e) => handleChange("from", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.from}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5rem"} mt={1}>
                To
              </Typography>
              <TextField
                onChange={(e) => handleChange("to", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.to}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.6rem"} mt={1}>
                Miles
              </Typography>
              <TextField
                onChange={(e) => handleChange("miles", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.miles}
                size="small"
              />
            </Grid>

            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.2rem"} mt={1}>
                Miles out
              </Typography>
              <TextField
                onChange={(e) => handleChange("deadmiles", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.deadmiles}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.6rem"} mt={1}>
                Invoice Rate
              </Typography>
              <TextField
                onChange={(e) => handleChange("invoiceRate", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.invoiceRate}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5rem"} mt={1}>
                Driver Rate
              </Typography>
              <TextField
                onChange={(e) => handleChange("driverRate", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.driverRate}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
              <Typography width={"8rem"} mt={1}>
                Type
              </Typography>
              <TextField
                onChange={(e) => handleChange("paymentType", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.paymentType}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
              <Typography width={"8rem"} mt={1}>
                Comments
              </Typography>
              <TextField
                onChange={(e) => handleChange("comments", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.comments}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"9.6rem"} mt={1}>
                Invoice By
              </Typography>
              <TextField
                onChange={(e) => handleChange("invoiceBy", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.invoiceBy}
                size="small"
              />
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"8rem"} mt={1}>
                Booked By
              </Typography>
              <TextField
                onChange={(e) => handleChange("bidder", e.target.value.trim())}
                placeholder={"None"}
                fullWidth
                hiddenLabel
                defaultValue={data.bidder}
                size="small"
              />
            </Grid>
            <Grid display="flex" justifyContent={"left"} item xs={6}>
              <FormGroup>
                <FormControlLabel
                  label="Delayed"
                  labelPlacement="start"
                  sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      onChange={(e) => handleChange("delayed", e.target.checked)}
                      checked={changedFormValues.delayed ?? data.delayed}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 30, ml: 1 } }}
                    />
                  }
                />
              </FormGroup>
            </Grid>
            <Grid display="flex" justifyContent={"left"} item xs={6}>
              <FormGroup>
                <FormControlLabel
                  label="Driver Paid"
                  labelPlacement="start"
                  sx={{ ml: 0 }}
                  control={
                    <Checkbox
                      onChange={(e) => handleChange("paid", e.target.checked)}
                      checked={changedFormValues.paid ?? data.paid}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 30, ml: 1 } }}
                    />
                  }
                />
              </FormGroup>
            </Grid>
            <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
              <Typography width={"5.7rem"} mt={1}>
                Status
              </Typography>
              <SelectComponent
                title={"Status"}
                options={[
                  {
                    label: "UNPAID",
                    value: "UNPAID",
                  },
                  {
                    label: "FACTORING",
                    value: "FACTORING",
                  },
                  {
                    label: "PAID",
                    value: "PAID",
                  },
                  {
                    label: "DANGER",
                    value: "DANGER",
                  },
                ]}
                selectedOptions={changedFormValues.status ?? data.status}
                setSelectedOptions={(status) => {
                  handleChange("status", status[0]);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete(data)} variant="outlined" startIcon={<RemoveIcon />} color="error">
            Delete invoice
          </Button>
          <Button
            startIcon={<PublishIcon />}
            disabled={Object.keys(changedFormValues).length === 0}
            variant="outlined"
            color="success"
            onClick={() => handleSubmit({ ...changedFormValues }, setChangedFormValues)}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

EditModal.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default memo(EditModal);
