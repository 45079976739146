import { memo } from "react";
import Grid from "@mui/material/Grid";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
const CustomCheckBoxAndTextField = ({data, changedFormValues, handleChange}) => {
  return (
    <>
      <Grid display="flex" justifyContent={"left"} item xs={6}>
        <FormGroup>
          <FormControlLabel
            label="Available"
            labelPlacement="start"
            sx={{ ml: 0 }}
            control={
              <Checkbox
                onChange={(e) => handleChange("Available", e.target.checked)}
                checked={changedFormValues.Available ?? data.Available}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30, ml: 1 } }}
              />
            }
          />
        </FormGroup>
      </Grid>
      <Grid display="flex" justifyContent={"left"} item xs={6}>
        <FormGroup>
          <FormControlLabel
            label="Hold"
            labelPlacement="start"
            control={
              <Checkbox
                onChange={(e) => {
                  console.log(changedFormValues)
                  handleChange("hold", e.target.checked)}}
                checked={changedFormValues.hold ?? data.hold}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 30, ml: 1 } }}
              />
            }
          />
        </FormGroup>
      </Grid>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
        <Typography width={"5.7rem"} mt={1}>
          Unit
        </Typography>
        <TextField
          onChange={(e) => handleChange("Unit", e.target.value.trim())}
          placeholder={"None"}
          fullWidth
          hiddenLabel
          defaultValue={data.Unit}
          size="small"
        />
      </Grid>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
        <Typography width={"4rem"} mt={1}>
          Name
        </Typography>
        <TextField
          onChange={(e) => handleChange("Name", e.target.value.trim())}
          placeholder={"None"}
          fullWidth
          hiddenLabel
          defaultValue={data.Name}
          size="small"
        />
      </Grid>
    </>
  );
};

export default memo(CustomCheckBoxAndTextField);
