import { Typography, TextField } from "@mui/material";
import formatDate from "../../utils/formatDate";
import handleColor from "../../utils/handleColor";
import { useState, useMemo } from "react";
import SingleSelectFilterInput from "./SingleSelectFilterInput";

const CustomFilterTextField = ({ column, onFilterChange }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const val = event.target.value;
    setValue(val);
    onFilterChange(val ? { columnField: column.field, operatorValue: 'contains', value: val } : null);
  };

  return <TextField value={value} onChange={handleChange} />;
};


const Header = ({ mode }) => {
  console.log(mode)
	const columns = useMemo( () => [
		{
			field: "Unit",
			headerName: "Unit",
			width: 150,
			sortable: true,
			sortOnClick: true,
      renderCell: (params) => {
        
        return(
        <Typography fontSize={17} fontWeight={"bolder"}>{params.value}</Typography>
        )
      }
		},
		{
			field: "Name",
			headerName: "Driver/Owner",
			width: 300,
		},
		{
			field: "Available",
			headerName: "Available & Date & Time",
			width: 300,
			sortable: false,
      filterOperators: [
        {
          label: 'Status',
          value: 'statusFilter',
          getApplyFilterFn: (filterItem) => {
            console.log(filterItem)
            return (params) => {
              console.log(params)
              if (filterItem.value === 'Hold') {
                return params.hold;
              }
              if (filterItem.value === 'Available') {
                return (params.Available && !params.hold);
              }
              if (filterItem.value === 'NotAvailable') {
                return (!params.Available && !params.hold);
              }
              if (filterItem.value === 'all') {
                return params;
              }
              
            };
          },
          InputComponent: SingleSelectFilterInput
        }
      ]
  ,  
			renderCell: (params) => {
				const item = params.value;
				return item.hold ? (
					<Typography color="red">
						ON HOLD <br /> Since {formatDate(item.updatedDate)}
					</Typography>
				) : item.Available ? (
					<>
						<Typography color="white">
							{item.City}, {item.State} ({item.cityByUser ? " " + item.cityByUser : "None"}){" "}
						</Typography>
						<Typography color={handleColor(item.Date, "available")}>{formatDate(item.Date)}</Typography>
					</>
				) : (
					<Typography color="red">
						NOT AVAILABLE <br /> Since {formatDate(item.updatedDate)}
					</Typography>
				);
			},
		},
		...(mode === "closestUnits"
			? [
					{
						field: "distance",
						headerName: "Distance",
						width: 150,
						sortable: true,
						sortOnClick: true,
					},
			  ]
			: []),
		{
			field: "Type",
			headerName: "Type",
			width: 150,
		},
		{
			field: "DimensionsPayload",
			headerName: "Dimensions & Payload",
			width: 180,
      renderCell: (params) => {
        return params.value
      }
		},
		{
			field: "UpdatedByDate",
			headerName: "Updated By & Date",
			width: 200,
      renderCell: (params) => {
        return params.value
      }
		},
		{
			field: "Notes",
			headerName: "Notes",
			width: 150,
      flex: 1

		},
		{
			field: "BidNotes",
			headerName: "Bid Notes",
			width: 150,
		},
		...(mode === "vehicles"
			? [
					{
						field: "Actions",
						headerName: "Actions",
						width: 150,
            renderCell: (params) => {
              return params.value
            }
					},
			  ]
			: []),
	], []);

	return columns;
};

export default Header;
