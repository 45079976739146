import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

const cards = [
  // green
  {
    key: "available",
    title: "Available",
    icon: CheckCircleIcon,
    color: "linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)",
  },
  // red
  {
    key: "notAvailable",
    title: "Not Available",
    icon: RemoveCircleIcon,
    color: "linear-gradient(60deg, rgba(245,0,87,1) 0%, rgba(255,138,128,1) 100%)",
  },
];

export default cards;
