import { Card, CardContent, Typography, Grid, TextField } from "@mui/material";


const renderElement = (label, value, width) => (
    <Grid item xs={width}>
      <TextField inputProps={{readOnly: true}} size="small" fullWidth={ true } label={label} defaultValue={value} variant="outlined" />
    </Grid>
  );

const Broker = ({ data: { load, broker } }) => {
  return (
    <Card sx={{ minWidth: "100%", minHeight: "100%", mt: "1rem" }}>
      <CardContent>
        <Typography align="left" variant="h5" gutterBottom>
          Broker Information
        </Typography>
        <Grid container rowSpacing={2} spacing={2} mt={1} sx={{ userSelect: "none" }}>
          {renderElement("Broker", load?.broker, 3)}
          {renderElement("Company Name", broker?.companyName, 3)}
          {renderElement("Company Address", broker?.companyAddress, 3)}
          {renderElement("Company Phone", broker?.phone, 3)}
          {renderElement("Posted by", broker?.postedBy, 4)}
          {renderElement("Posted by Phone", broker?.postedByPhone, 4)}
          {renderElement("Email", broker?.email, 4)}
          
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Broker;
