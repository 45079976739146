import React from "react";
import MaterialTable from "@material-table/core";
import formatDate from "../../../utils/formatDate";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import handleCopyText from "../../../utils/handleCopyText";
import handleColor from "../../../utils/handleColor";
import { Button, Tooltip, Typography } from "@mui/material";

const commonStyle = {
	whiteSpace: "nowrap",
};

const Table = ({ data, getDispatch, handleClickOpen, company, loading }) => {
	data = data?.invoices?.filter((item) => item.company === company);

	const transformedData = data?.map((item) => {
		const ratePerMileGL = Math.floor((item.invoiceRate / item.miles) * 100) / 100;
		const ratePerMileDriver = Math.floor((item.driverRate / item.miles) * 100) / 100;
		const received = item.invoiceRate - item.invoiceRate * 0.03;
		const dispatchInterest = (item.invoiceRate - item.driverRate) * 0.1;
		const balance = Math.floor(received - dispatchInterest - item.driverRate);

		return {
			invoiceid: `${item.index}-${item.unit}`,
			status: item.status,
			dateWon: formatDate(item.created),
			customer: item.customer,
			reference: item.reference ? item.reference : "None",
			puWeek: item.weekNumber,
			puDate: item.puDate,
			from: item.from,
			to: item.to,
			miles: `${item.deadmiles} / ${item.miles}`,
			amount: `${item.invoiceRate} / ${item.driverRate}`,
			ratePerMile: `${ratePerMileGL} / ${ratePerMileDriver}`,
			paymentType: item.paymentType,
			received: received,
			bookedBy: `${item.bidder} / ${item.lastChangedBy} / ${item.invoiceBy ? item.invoiceBy : "None"}`,
			interestBalance: `${dispatchInterest} / ${balance}`,
			delayedPaid: `${item.delayed} \n ${item.paid}`,
			commentsDate: `${item.comments ? item.comments : "None"} / ${formatDate(item.lastChangedDate)}`,
			actions: (
				<div style={{ display: "flex", gap: "0.5rem" }}>
					<Tooltip
						placement="top"
						arrow
						title="Copy Dispatch"
					>
						<span>
							<Button
								disabled={loading}
								variant="outlined"
								size="large"
								onClick={async () => {
									const dispatchResult = await getDispatch(item._id);
									if (dispatchResult) {
										handleCopyText.dispatch(item, dispatchResult);
									} else {
										alert("Load is expired");
									}
								}}
							>
								<ContentCopyIcon />
							</Button>
						</span>
					</Tooltip>
					<Tooltip
						placement="top"
						arrow
						title="Edit invoice"
					>
						<span>
							<Button
								disabled={loading}
								variant="outlined"
								size="large"
								onClick={() => handleClickOpen(item)}
							>
								<ModeEditIcon />
							</Button>
						</span>
					</Tooltip>
				</div>
			),
		};
	});

	return (
		<MaterialTable
			options={{
				filtering: true,
				paginationType: "stepped",
				paging: false,
				showTitle: false,
			}}
			columns={columns}
			data={transformedData}
		/>
	);
};

const columns = [
	{ title: "Invoice #", field: "invoiceid", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Status", field: "status", cellStyle: commonStyle, headerStyle: commonStyle,           lookup: { "UNPAID": 'UNPAID', "FACTORING": 'FACTORING', "PAID": 'PAID', "DANGER": 'DANGER' },
  render: rowData => {
    
    
    return (<Typography color={handleColor(rowData.status.toLowerCase(), "status")}>{rowData.status}</Typography>)} },
	{ title: "Date won", field: "dateWon", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Customer", field: "customer", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Reference", field: "reference", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "PU Week", field: "puWeek", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "PU Date", field: "puDate", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "From", field: "from", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "To", field: "to", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Miles (Out / Load)", field: "miles", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Amount (GL / Driver)", field: "amount", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Rate/mile (GL / Driver)", field: "ratePerMile", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Payment Type", field: "paymentType", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Received", field: "received", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Booked / Edited / Invoice by", field: "bookedBy", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Disp. Interest / Balance", field: "interestBalance", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Delayed / Driver Paid", field: "delayedPaid", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Comments / Last Edited Date", field: "commentsDate", cellStyle: commonStyle, headerStyle: commonStyle },
	{ title: "Actions", field: "actions", cellStyle: commonStyle, headerStyle: commonStyle, filtering: false },
];

export default Table;
