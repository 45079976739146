import { TableCell, TableRow, Typography } from "@mui/material";
import { memo } from "react";

const getColor = (rank) => {
  if (rank === "A" || rank === "B")  return "#2ebc60"
  else if (rank === "B" || rank === "C") return "#ffde56"
  else return "red"
}
 
const DriverRow = ({ data: { broker, index } }) => (
  <TableRow hover key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
    <TableCell>{broker.company}</TableCell>
    <TableCell>{broker.MC}</TableCell>
    <TableCell><Typography fontWeight="bold" color={getColor(broker.CredRate)}>{broker.CredRate} </Typography></TableCell>
    <TableCell><Typography fontWeight="bold" color="red"> {broker.CredRate === "N" && "Too New"}  {broker.NoBuyDesc} </Typography></TableCell>
    <TableCell>{broker.city}, {broker.st}</TableCell>
    <TableCell>{broker.addr1}</TableCell>
    <TableCell>{broker.phone1}</TableCell>
  </TableRow>
);

export default memo(DriverRow);
