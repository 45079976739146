import axiosInstance from "../utils/axios";

// GET request to retrieve users
const get = () => {
  return axiosInstance.get("/users").then((response) => {
    return response?.data;
  });
};

// POST request to add a new user
const post = (data) => {
  return axiosInstance.post("/users", data).then((response) => {
    return response?.data;
  });
};

// PUT request to update an existing user
const put = (data) => {
  return axiosInstance.put(`/users`, data).then((response) => {
    return response?.data;
  });
};

// DELETE request to delete a user
const del = (data) => {
  return axiosInstance.delete(`/users`, {data: {_id: data}}).then((response) => {
    return response?.data;
  });
};

export default { get, post, put, del };
