import Card from "../../components/Card/Card";
import Cards from "../../configs/homeCards.js";
import Grid from "@mui/material/Grid";
import Leaderboard from "./Leaderboard";
import getHome from "../../api/home";
import { useState, useEffect } from "react";

export default function Home() {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      setData(await getHome());
    })();
  }, []);

  return (
    <>
      <Grid container rowSpacing={1} spacing={8} sx={{ userSelect: "none" }}>
        {Cards.map((card) => {
          return (
            <Grid item xs={3} key={card.key}>
              <Card
                title={card.title}
                value={data[card.key] ? data[card.key] : 0}
                iconPerformance={card.icon}
                color={card.color}
              />
            </Grid>
          );
        })}
        <Leaderboard topToday={data["topToday"]}  topTotal={data["topTotal"]} />
      </Grid>
    </>
  );
}
