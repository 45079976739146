import loads from "../api/loads";
import Try from "./tryPromise";

const handleDistance = (load, setLoading, setBidInfo, setOpen) => {
  const getInfo = async () => {
    setLoading(true);
    const response = await loads.bid(load.from.location, load.orderNo);
    setBidInfo({ ...response, load });
    setOpen(true);
    return setLoading(false);
  };
  Try(getInfo, "load");
};



export default handleDistance