import axiosInstance from "../utils/axios";

const get = (from, to, types) => {
  return axiosInstance.post("/loads", { from, to, types }).then((response) => {
    return response?.data;
  });
};


const bid = (location, orderNo) => {
  return axiosInstance.post("/vehicles", {location: location, orderNo: orderNo }).then((response) => {
    return response?.data;
  });
};


const send = (data) => {
  return axiosInstance.post("/rate", {...data}).then((response) => {
    return response?.data;
  });
};


const find = (data) => {
  return axiosInstance.post("/findLoad", {searchQuery : data}).then((response) => {
    return response?.data;
  });
}

export default { get, bid, send, find };
