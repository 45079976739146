import { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Slide, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PublishIcon from "@mui/icons-material/Publish";
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle({ children, onClose, ...other }) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AddUnitModal({ open, setOpen, loading, handleAdd }) {
  const handleClose = () => {
    setUnit("");
    setOpen(false);
  };
  const [unit, setUnit] = useState("");

  return (
    <div>
      <BootstrapDialog
        PaperProps={{
          variant: "outlined",
          elevation: 0,
        }}
        fullWidth
        maxWidth="xs"
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Register New Unit
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <TextField
            onChange={(e) => setUnit(e.target.value)}
            value={unit}
            fullWidth
            label="Unit №"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleAdd(unit)}
            autoFocus
            disabled={(loading || unit.trim() === "")}
            variant="outlined"
            color="success"
            startIcon={<PublishIcon />}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default AddUnitModal;
