import { useState, useEffect } from "react";
import api from "../../api/invoices";
import EditModal from "../../components/Edit Invoice/Modal";
import Try from "../../utils/tryPromise";
import Table from "./Bid Table/Table";
import OptionsCard from "./Cards/Options";

const Invoices = ({ lastJsonMessage }) => {
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [modalData, setModalData] = useState({});
	const [_id, set_id] = useState(null);
	const [company, setComapny] = useState("gass");

	const handleChange = (event, newValue) => {
		setComapny(newValue);
	};

	const refresh = async () => {
		await Try(
			async () => {
				setData(await api.get());
			},
			"invoices",
			setLoading
		);
	};

	const getDispatch = async (id) => {
		return api.dispatch({ _id: id });
	};

	const handleEditSubmit = async (data, setChangedFormValues) => {
		await Try(
			async () => {
				const response = await api.edit({ _id, ...data });
				if (response) {
					setOpen(false);
					setChangedFormValues({});
				}
			},
			"editInvoice",
			setLoading
		);
	};

	const handleCreate = async (data) => {
		await Try(
			async () => {
				await api.create({ company: company });
			},
			"createInvoice",
			setLoading
		);
	};

	const handleDelete = async (data) => {
		const confirmDelete = window.confirm(
			`Are you certain you wish to permanently remove invoice ${data.index}-${data.unit}?`
		);
		if (!confirmDelete) return;

		await Try(
			async () => {
				const response = await api.remove(_id);
				if (response) setOpen(false);
			},
			"deleteInvoice",
			setLoading
		);
	};

	useEffect(() => {
		refresh();
	}, [lastJsonMessage]);

	const handleClickOpen = (item) => {
		setOpen(true);
		setModalData(item);
		set_id(item._id);
	};

	console.log(data);

	return (
		<>
			<OptionsCard
				company={company}
				handleChange={handleChange}
        handleCreate={handleCreate}
			/>

			<EditModal
				data={modalData}
				open={open}
				setOpen={setOpen}
				handleSubmit={handleEditSubmit}
				handleDelete={handleDelete}
			/>
			<Table
				data={data}
				company={company}
				handleClickOpen={handleClickOpen}
				loading={loading}
				getDispatch={getDispatch}
			/>
		</>
	);
};

export default Invoices;
