import { TableCell, TableRow, Button, Tooltip } from "@mui/material";
import { memo } from "react";
import formatDate from "../../../utils/formatDate";
import DoneAllIcon from '@mui/icons-material/DoneAll';

const Row = ({ data: { item, index }, handleClickOpen, loading }) => (
  <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
    <TableCell>{item.orderNo}</TableCell>
    <TableCell>{item.unit}</TableCell>
    <TableCell>{item.load.broker} <br /> {formatDate(item.date)}</TableCell>
    <TableCell>{item.load?.from.location}</TableCell>
    <TableCell>{item.load?.to.location}</TableCell>
    <TableCell>{item.deadmiles}</TableCell>
    <TableCell>{item.gass.rate ? item.gass.rate : 0}</TableCell>
    <TableCell>{item.lmux.rate ? item.lmux.rate : 0}</TableCell>
    <TableCell>{item.bidder}</TableCell>
    <TableCell>
      <Tooltip placement="top" arrow title="Submit Bid to Invoices">
        <span>
          <Button
            disabled={loading}
            variant="outlined"
            size="large"
            onClick={() => handleClickOpen(item._id)}
          >
            <DoneAllIcon />
          </Button>
        </span>
      </Tooltip>
    </TableCell>
  </TableRow>
);

export default memo(Row);
