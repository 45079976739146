const calculateTimeDifference = (date) => {
  const currentTime = new Date();
  const driverTime = new Date(date * 1000); // JavaScript expects timestamps in milliseconds
  const differenceInMilliseconds = currentTime - driverTime;
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  return differenceInHours;
};

const getColorByValue = (value, lowerLimit, upperLimit) => {
  if (value < lowerLimit) {
    return "#43f24d"; // green
  } else if (value < upperLimit) {
    return "yellow";
  }
  return "#ff3200"; // red
};

const getColorByStatus = (value) => {
  const statuses = {
    unpaid: "white",
    factoring: "#00b0f0",
    paid: "#ffc000",
    danger: "#ff0000",
  };

  return statuses[value];
};

export const handleColor = (value, type) => {
  if (type === "distance") {
    return getColorByValue(value, 100, 200);
  } else if (type === "time") {
    const timeDifference = calculateTimeDifference(value);
    return getColorByValue(timeDifference, 8, 16);
  } else if (type === "available") {
    const timeDifference = calculateTimeDifference(value);
    return getColorByValue(timeDifference, 24, 48);
  } else if (type === "status") {
    return getColorByStatus(value);
  }
  return "black";
};

export default handleColor;
