import { memo } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
const SignatureEdit = ({data, handleChange}) => {
  return (
    <>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
        <Typography width={"12rem"} mt={1}>
        Plate number 
        </Typography>
        <TextField
          onChange={(e) => handleChange("plateNumber", e.target.value.trim())}
          placeholder={"None"}
          fullWidth
          hiddenLabel
          defaultValue={data.plateNumber}
          size="small"
        />
      </Grid>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={6}>
        <Typography width={"12rem"} mt={1}>
        Driver number
        </Typography>
        <TextField
          onChange={(e) => handleChange("driverNumber", e.target.value.trim())}
          placeholder={"None"}
          fullWidth
          hiddenLabel
          defaultValue={data.driverNumber}
          size="small"
        />
      </Grid>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
        <Typography width={"10.1rem"} mt={1}>
        Additional info 
        </Typography>
        <TextField
          onChange={(e) => handleChange("additionalInfo", e.target.value.trim())}
          placeholder={"None"}
          fullWidth
          hiddenLabel
          defaultValue={data.additionalInfo}
          size="small"
        />
      </Grid>
    </>
  );
};

export default memo(SignatureEdit);
