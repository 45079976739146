import { Slide } from "react-toastify";

const ToastConfig = () => {
  return {
    transition: Slide,
    position: "bottom-right",
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    newestOnTop: true,
    theme: "dark",
  };
};

export default ToastConfig;
