// Components
import Select from "../../../components/Select/Select";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Buttons from "../Buttons/Buttons";
import vehicles from "../../../configs/vehicles.js";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
// Utils
import { getStates } from "../../../configs/states";

const OptionsCard = ({ from, setFrom, to, setTo, types, setTypes, handleAction, loading }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleAcordion = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        minWidth: "100%",
        "&.Mui-expanded": {
          minHeight: "200px",
          maxHeight: "200px",
        },
      }}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ cursor: "pointer" }} onClick={() => toggleAcordion()} />}
        sx={{ cursor: "unset !important" }}
      >
        {" "}
        <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
          Options
        </Typography>
        <Grid item gap={1} display={"flex"} justifyContent={"right"} alignItems={"right"} sx={{ mr: 3 }} xs={true}>
          <Buttons handleAction={handleAction} loading={loading} />
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container sx={{ userSelect: "none" }}>
          <Grid item xs={4}>
            <Select
              sx={{ m: 1 }}
              multiple
              title={"From"}
              options={getStates()}
              selectedOptions={from}
              setSelectedOptions={setFrom}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              sx={{ m: 1 }}
              title={"To"}
              options={getStates("to")}
              selectedOptions={to}
              setSelectedOptions={setTo}
            />
          </Grid>
          <Grid item xs={4}>
            <Select
              sx={{ m: 1 }}
              multiple
              title={"Choose Vehicle Types to Hide"}
              options={vehicles}
              selectedOptions={types}
              setSelectedOptions={setTypes}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default OptionsCard;
