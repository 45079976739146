// AddUserDialog.js
import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';

const AddUserDialog = ({ open, handleClose, handleAddUser }) => {
  const [newUser, setNewUser] = useState({ name: '', password: '' });
  const [generatedPassword, setGeneratedPassword] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const generatePassword = () => {
    const password = Math.random().toString(36).slice(-10).toUpperCase();
    setGeneratedPassword(password);
    setNewUser({ ...newUser, password });
    copyToClipboard(password);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert('Password copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New User</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          type="text"
          fullWidth
          value={newUser.name}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="password"
          label="Password"
          type="text"
          fullWidth
          value={newUser.password || generatedPassword}
          onChange={handleInputChange}
          disabled
        />
        <Button onClick={generatePassword} color="primary" variant="outlined">
          Generate Password
        </Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={() => handleAddUser(newUser)}
          color="primary"
          variant="outlined"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;
