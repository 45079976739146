import React from "react";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import MButton from "@mui/material/Button";
const Button = ({loading, setOpen}) => {
  return (
    <MButton
    disabled={loading}
      fullWidth
      size="large"
      onClick={() => setOpen(true)}
      variant="outlined"
      startIcon={<PersonAddAlt1Icon />}
      sx={{
        minHeight: "100%",
      }}
    >
      New Unit
    </MButton>
  );
};

export default Button;
