import * as React from "react";
import { default as TableM } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TfiCrown } from "react-icons/tfi";
const template = [
  { name: "Joe Biden", bidsToday: 2021, bidsTotal: 2025 },
  { name: "Donald Trump", bidsToday: 2017, bidsTotal: 2021 },
  { name: "Barack Obama", bidsToday: 2009, bidsTotal: 2017 },
  { name: "Thomas Jefferson", bidsToday: 1801, bidsTotal: 1809 },
  { name: "George Washington", bidsToday: 1732, bidsTotal: 1797 },
];
export default function Table({ data }) {
  return (
    <TableContainer component={Paper}>
      <TableM sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Today</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(data ? data : template).map((row, index) => {return (
            <TableRow hover key={row.name} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {index + 1 === 1 ? <TfiCrown /> : index + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.bidsToday}</TableCell>
              <TableCell align="right">{row.bidsTotal}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </TableM>
    </TableContainer>
  );
}
