import { useState, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {  Dialog, DialogTitle, DialogContent, IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import RateModal from "../Rate/Modal";
import DriverTable from "./Table";
import Load from "./Load";
import Broker from "./Broker";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle({ children, onClose, ...other }) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function CustomizedDialogs({ open, setOpen, bidInfo }) {
  const handleClose = () => setOpen(false);
  const [rateData, setRateData] = useState({});
  const [rateModal, setRateModal] = useState(false);
  
  return (
    <div>
      <RateModal open={rateModal} setOpen={setRateModal} data={rateData}/>
      <BootstrapDialog
        PaperProps={{
          variant: "outlined",
          elevation: 0,
        }}
        fullWidth
        maxWidth="xl"
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Load Window
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <DriverTable data={bidInfo} setRateModal={setRateModal} setRateData={setRateData} />
          <Load data={bidInfo} />
          <Broker data={bidInfo} />
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default memo(CustomizedDialogs);
