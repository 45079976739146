import api from "../../api/payslip.js";
import { useState, useEffect } from "react";
import Try from "../../utils/tryPromise.js";
import SearchCard from "./Card/index.jsx";
const Payslip = () => {
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState([]);
	const [month, setMonth] = useState([]);
	const [wage, setWage] = useState();
	const [percetage, setPercentage] = useState();
	const [support, setSupport] = useState(false);

	const refresh = async () => {
		await Try(
			async () => {
				setData(await api.get());
			},
			"users",
			setLoading
		);
	};

	const handleSearch = async (event) => {
		event.preventDefault();
		Try(
			async () => {
				const response = await api.post({
					user: user[0],
					fix: wage,
					month: month[0],
					percentage: percetage,
					support: support,
				});

                console.log(response)
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", `${user}.xlsx`); // Specify the file name
				document.body.appendChild(link);
				link.click();
				link.remove(); // Clean up the DOM
			},
			"payslip",
			setLoading
		);
	};

	useEffect(() => {
		refresh();
	}, []);

	return (
		<div>
			<SearchCard
				data={
					data
						? data.users.map((name) => ({
								label: name,
								value: name,
						  }))
						: [{ label: "test", value: "test" }]
				}
				loading={loading}
				user={user}
				setUser={setUser}
				month={month}
				setMonth={setMonth}
				setWage={setWage}
				setPercentage={setPercentage}
				support={support}
				setSupport={setSupport}
				handleSearch={handleSearch}
			/>
		</div>
	);
};

export default Payslip;
