import { DataGridPremium, GridToolbar, gridClasses } from "@mui/x-data-grid-premium";
import { useMemo } from "react";
import Header from "./Header.jsx";
import Row from "./Row.jsx";
import { Box } from "@mui/material";

export default function DataGridDemo({ mode, data = [], searchTerm = "", setOpen, setModalData, loading }) {

	const filteredData = useMemo(() => {
		const loweredSearchTerm = searchTerm.toLowerCase();

		return data.filter((item) => {
			let unit = item.Unit;

			// Only convert to lowercase if not already
			if (unit !== unit.toLowerCase()) {
				unit = unit.toLowerCase();
			}

			return unit.includes(loweredSearchTerm);
		});
	}, [data, searchTerm]);

	return (
		<Box sx={{ height: 400, width: "100%" }}>
			<DataGridPremium
				getRowHeight={() => "auto"}
				// autosizeOptions={{
				// 	includeHeaders: true,
				// }}
				slots={{
					toolbar: GridToolbar,
				}}
				sx={{
					[`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
						outline: "none",
					},
					[`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
						outline: "none",
					},
				}}
				rows={Row({ loading: loading, data: filteredData, setOpen: setOpen, setModalData: setModalData })}
				columns={Header({mode})}
				autoHeight={true}
			/>
		</Box>
	);
}
