import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Header = () => {
    return (
        <TableHead sx={{ userSelect: "none" }}>
          <TableRow>
            <TableCell>Broker</TableCell>
            <TableCell >From</TableCell>
            <TableCell>To</TableCell>
            <TableCell >
              Vehicle Type <br /> Miles
            </TableCell>
            <TableCell >
              PCS <br /> Weight
            </TableCell>
            <TableCell >Posted <br /> Expires</TableCell>
            <TableCell >Rate  <br /> Notes</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
    );
};

export default Header;