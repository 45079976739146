import handlebars from "handlebars";
const signature = `<!DOCTYPE html>
<html lang="en-US">
	<head>
		<meta charset="utf-8" />
		<meta
			name="generator"
			content="Aspose.Words for .NET 24.3.0"
		/>
		<title></title>
		<style type="text/css">
			@page Section_1 {
				size: 612pt 792pt;
				margin: 56.7pt 42.5pt 56.7pt 85.05pt;
				-aw-footer-distance: 35.4pt;
				-aw-header-distance: 35.4pt;
			}
			div.Section_1 {
				page: Section_1;
			}
			body {
				font-family: "Times New Roman";
				font-size: 12pt;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				margin: 0pt;
			}
			li,
			table {
				margin-top: 0pt;
				margin-bottom: 0pt;
			}
			h1 {
				margin-top: 12pt;
				margin-bottom: 0pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: "Times New Roman";
				font-size: 24pt;
				font-weight: bold;
				font-style: normal;
				color: #2f5496;
			}
			h2 {
				margin-top: 2pt;
				margin-bottom: 0pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: "Times New Roman";
				font-size: 18pt;
				font-weight: bold;
				font-style: normal;
				color: #2f5496;
			}
			h3 {
				margin-top: 2pt;
				margin-bottom: 0pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: "Times New Roman";
				font-size: 14pt;
				font-weight: bold;
				font-style: normal;
				color: #1f3763;
			}
			h4 {
				margin-top: 2pt;
				margin-bottom: 0pt;
				text-align: left;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: "Times New Roman";
				font-size: 12pt;
				font-weight: bold;
				font-style: normal;
				color: #2f5496;
			}
			h5 {
				margin-top: 2pt;
				margin-bottom: 0pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: "Times New Roman";
				font-size: 10pt;
				font-weight: bold;
				font-style: normal;
				color: #2f5496;
			}
			h6 {
				margin-top: 2pt;
				margin-bottom: 0pt;
				page-break-inside: avoid;
				page-break-after: avoid;
				font-family: "Times New Roman";
				font-size: 8pt;
				font-weight: bold;
				font-style: normal;
				color: #1f3763;
			}
			.divWordSection1 {
				font-size: 12pt;
			}
			.liMsoNormal {
				font-family: Arial;
				font-size: 11pt;
			}
			.pMsoNormal {
				font-family: Arial;
				font-size: 11pt;
			}
			span.Heading1Char {
				font-family: "Calibri Light";
				font-size: 16pt;
				color: #2f5496;
			}
			span.Heading2Char {
				font-family: "Calibri Light";
				font-size: 13pt;
				color: #2f5496;
			}
			span.Heading3Char {
				font-family: "Calibri Light";
				font-size: 12pt;
				color: #1f3763;
			}
			span.Heading4Char {
				font-family: "Calibri Light";
				font-style: italic;
				color: #2f5496;
			}
			span.Heading5Char {
				font-family: "Calibri Light";
				color: #2f5496;
			}
			span.Heading6Char {
				font-family: "Calibri Light";
				color: #1f3763;
			}
			span.headerLineText {
				font-family: Calibri;
				font-size: 11pt;
				font-weight: normal;
			}
			span.headerLineTitle {
				font-family: Calibri;
				font-size: 11pt;
				font-weight: bold;
			}
			.MsoNormalTable {
			}
		</style>
	</head>
	<body>
		<div class="Section_1">
			<h4 style="margin-top: 0pt; margin-bottom: 15.95pt; page-break-inside: auto; page-break-after: auto">
			</h4>
			</p>
			<table
				class="MsoNormalTable"
				style="width: 206.45pt; margin-left: 0.1pt; border: 1pt solid #000000; border-collapse: collapse"
			>
				<tr style="height: 14.25pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">UNIT #</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal"><span style="font-family: Calibri; font-weight: bold">{{UNIT}}</span></p>
					</td>
				</tr>
				<tr style="height: 14.25pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">DIMENSIONS</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span><span>&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-family: Calibri; font-weight: bold; color: #ff0000">{{DIMS}}</span>
						</p>
					</td>
				</tr>
				<tr style="height: 15.6pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">PAY LOAD</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span><span>&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-family: Calibri; font-weight: bold">{{PAYLOAD}} LBS</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span>
						</p>
					</td>
				</tr>

				<tr style="height: 18.35pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">VEHICLE</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal"><span style="font-family: Calibri; font-weight: bold">{{TYPE}} </span></p>
					</td>
				</tr>
				<tr style="height: 18.35pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">ADDITIONAL INFO</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal"><span style="font-family: Calibri; font-weight: bold">{{INFO}}</span></p>
					</td>
				</tr>
				<tr style="height: 16.95pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">PLATE NUMBER</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span><span>&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal"><span style="font-family: Calibri; font-weight: bold">{{PLATENUMBER}}</span></p>
					</td>
				</tr>
				<tr style="height: 18.35pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">DRIVER NAME</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span><span>&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal"><span style="font-weight: bold">{{NAME}}</span></p>
					</td>
				</tr>
				<tr style="height: 16.95pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">DRIVER NUMBER</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span><span>&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal"><span style="font-family: Calibri; font-weight: bold">{{DRIVERNUMBER}}</span></p>
					</td>
				</tr>
				<tr style="height: 27.75pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">LOGISTICS COORINATOR</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span><span>&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							border-bottom: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
800-493-9310 (ext.102)
						</p>
					</td>
				</tr>
				<tr style="height: 17.25pt">
					<td
						style="
							width: 104.45pt;
							border-right: 1pt solid #000000;
							padding-right: 5.4pt;
							padding-left: 5.4pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-size: 9pt; font-weight: bold; color: #1f497d">MC#</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span>
						</p>
					</td>
					<td
						style="
							width: 101.3pt;
							padding-right: 5.4pt;
							padding-left: 5.65pt;
							vertical-align: middle;
							background-color: #ffffff;
						"
					>
						<p class="pMsoNormal">
							<span style="font-family: Calibri; font-weight: bold">1096872</span
							><span style="font-family: Calibri; color: #2e74b5">&#xa0;</span>
						</p>
					</td>
				</tr>
			</table>
			<p class="pMsoNormal"><span>&#xa0;</span></p>
			<div style="margin-left: 22.45pt; background-color: #ffffff">
				<p
					class="liMsoNormal"
					style="
						margin-left: 13.55pt;
						text-indent: -13.55pt;
						-aw-import: list-item;
						-aw-list-level-number: 0;
						-aw-list-number-format: '';
						-aw-list-number-styles: 'bullet';
						-aw-list-padding-sml: 4.99pt;
					"
				>
					<span style="-aw-import: ignore"
						><span><span style="font-family: Symbol"></span></span
						><span style="width: 4.99pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: spaces"
							>&#xa0;&#xa0;
						</span></span
					><span style="font-family: Calibri; font-weight: bold; font-style: italic">24/7 team service</span
					><span style="font-family: Calibri">&#xa0;</span>
				</p>
			</div>
			<div style="margin-left: 22.45pt; background-color: #ffffff">
				<p
					class="liMsoNormal"
					style="
						margin-left: 13.55pt;
						text-indent: -13.55pt;
						-aw-import: list-item;
						-aw-list-level-number: 0;
						-aw-list-number-format: '';
						-aw-list-number-styles: 'bullet';
						-aw-list-padding-sml: 4.99pt;
					"
				>
					<span style="-aw-import: ignore"
						><span><span style="font-family: Symbol"></span></span
						><span style="width: 4.99pt; font: 7pt 'Times New Roman'; display: inline-block; -aw-import: spaces"
							>&#xa0;&#xa0;
						</span></span
					><span style="font-family: Calibri; font-weight: bold; font-style: italic">Sylectus Virtual Fleet user</span>
				</p>
			</div>
			<p
				class="pMsoNormal"
				style="font-size: 12pt; background-color: #ffffff"
			>
				<span style="font-family: Calibri">Looking forward to working with you.</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 12pt; background-color: #ffffff"
			>
				<span style="font-family: Calibri">&#xa0;</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">Regards,</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">Logistics Team</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">&#xa0;</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="color: #1f497d">Phone:</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d"> +1</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">561</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">208 1902</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="color: #1f497d">Fax:</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d">&#xa0;</span><span style="color: #1f497d">&#xa0;</span
				><span style="color: #1f497d"> +1 800 493 9315</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">MC</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> #</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> 1096872</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">DOT #</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> 3405390</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">SCAC</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> GLCK</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="color: #1f497d">&#xa0;</span>
			</p>
			<p
				class="pMsoNormal"
				style="background-color: #ffffff"
			>
				<span style="color: #1f497d">Nationwide LTL Expediting</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 9pt; background-color: #ffffff"
			>
				<span style="color: #1f497d">&#xa0;</span>
			</p>
			<p
				class="pMsoNormal"
				style="background-color: #ffffff"
			>
				<span style="font-weight: bold; color: #1f497d">L E T ’ S</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> D R I V E</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> T O</span
				><span style="font-weight: bold; color: #1f497d">&#xa0;</span
				><span style="font-weight: bold; color: #1f497d"> S U C C E S S !</span>
			</p>
			<p
				class="pMsoNormal"
				style="font-size: 12pt"
			>
				<span>&#xa0;</span>
			</p>
			<p class="pMsoNormal"><span>&#xa0;</span></p>
		</div>
	</body>
</html>
`


const template = handlebars.compile(signature);

const copySignature = (data) => {
    console.log(data);

    const signatureHTML = template({
        UNIT: data.Unit,
        DIMS: data.dims,
        PAYLOAD: data.payload,
        TYPE: data.TYPE,
        INFO: data.additionalInfo,
        PLATENUMBER: data.plateNumber,
        NAME: data.Name,
        DRIVERNUMBER: data.driverNumber,
    });

    const blob = new Blob([signatureHTML], { type: 'text/html' });
    const clipboardItem = new ClipboardItem({ 'text/html': blob });

    navigator.clipboard.write([clipboardItem])
        .then(() => {
            console.log('Signature copied to clipboard successfully!');
        })
        .catch(err => {
            console.error('Failed to copy signature to clipboard: ', err);
        });
};

export default copySignature;