import React from "react";
import { Table, TableBody, TableContainer, Paper, Card, CardContent, Typography } from "@mui/material";
import Row from "./Row.jsx";
import Header from "./Header";

export default function BidTable({ data, handleClickOpen, loading }) {
  return (
    <Card variant="elevation" sx={{ minWidth: 500, minHeight: "100%", mt: "1rem" }}>
      <CardContent>
        <Typography align="left" variant="h5" gutterBottom>
          History
        </Typography>
        <TableContainer component={Paper}>
          <Table stickyHeader sx={{ minWidth: "100%" }} size="small">
            <Header />
            <TableBody>
              {data.map((item, index) => (
                <Row key={item._id} data={{ item, index }} handleClickOpen={handleClickOpen} loading={loading} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
