import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";

import { blue } from "@mui/material/colors";

const companies = [{ name: "GASS LOGISTICS",  value: "gass" }, { name: "LOGIMAX", value: "lmux" }];

export default function SimpleDialog({ selectedValue, setSelectedValue, open, setOpen, handleSubmit }) {
  const [disabled, setDisabled] = useState(true);

  const handleClose = (value) => {
    setOpen(false);
    setDisabled(true);
  };

  const handleTextFieldChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    setDisabled(value.trim() === ""); // true if empty, false if not empty
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose the company from which you received the load</DialogTitle>
      <TextField
        sx={{ mx: 2 }}
        type="number"
        autoFocus
        label="Enter Driver's Rate"
        variant="outlined"
        onChange={handleTextFieldChange}
      />
      <List sx={{ pt: 0 }}>
        {companies.map((company) => (
          <ListItem disableGutters key={company.value}>
            <ListItemButton disabled={disabled} onClick={() => handleSubmit({driverRate: selectedValue, company: company.value})}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>{company.name.charAt(0)}</Avatar>
              </ListItemAvatar>
              <ListItemText primary={company.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}
