const states = [
    { label: "AK - ALASKA", value: "AK" },
    { label: "AL - ALABAMA", value: "AL" },
    { label: "AR - ARKANSAS", value: "AR" },
    { label: "AZ - ARIZONA", value: "AZ" },
    { label: "CA - CALIFORNIA", value: "CA" },
    { label: "CO - COLORADO", value: "CO" },
    { label: "CT - CONNECTICUT", value: "CT" },
    { label: "DC - WASHINGTON DC", value: "DC" },
    { label: "DE - DELAWARE", value: "DE" },
    { label: "FL - FLORIDA", value: "FL" },
    { label: "GA - GEORGIA", value: "GA" },
    { label: "HI - HAWAII", value: "HI" },
    { label: "IA - IOWA", value: "IA" },
    { label: "ID - IDAHO", value: "ID" },
    { label: "IL - ILLINOIS", value: "IL" },
    { label: "IN - INDIANA", value: "IN" },
    { label: "KS - KANSAS", value: "KS" },
    { label: "KY - KENTUCKY", value: "KY" },
    { label: "LA - LOUISIANA", value: "LA" },
    { label: "MA - MASSACHUSETTS", value: "MA" },
    { label: "MD - MARYLAND", value: "MD" },
    { label: "ME - MAINE", value: "ME" },
    { label: "MI - MICHIGAN", value: "MI" },
    { label: "MN - MINNESOTA", value: "MN" },
    { label: "MO - MISSOURI", value: "MO" },
    { label: "MS - MISSISSIPPI", value: "MS" },
    { label: "MT - MONTANA", value: "MT" },
    { label: "NC - NORTH CAROLINA", value: "NC" },
    { label: "ND - NORTH DAKOTA", value: "ND" },
    { label: "NE - NEBRASKA", value: "NE" },
    { label: "NH - NEW HAMPSHIRE", value: "NH" },
    { label: "NJ - NEW JERSEY", value: "NJ" },
    { label: "NM - NEW MEXICO", value: "NM" },
    { label: "NV - NEVADA", value: "NV" },
    { label: "NY - NEW YORK", value: "NY" },
    { label: "OH - OHIO", value: "OH" },
    { label: "OK - OKLAHOMA", value: "OK" },
    { label: "OR - OREGON", value: "OR" },
    { label: "PA - PENNSYLVANIA", value: "PA" },
    { label: "RI - RHODE ISLAND", value: "RI" },
    { label: "SC - SOUTH CAROLINA", value: "SC" },
    { label: "SD - SOUTH DAKOTA", value: "SD" },
    { label: "TN - TENNESSEE", value: "TN" },
    { label: "TX - TEXAS", value: "TX" },
    { label: "UT - UTAH", value: "UT" },
    { label: "VA - VIRGINIA", value: "VA" },
    { label: "VT - VERMONT", value: "VT" },
    { label: "WA - WASHINGTON", value: "WA" },
    { label: "WI - WISCONSIN", value: "WI" },
    { label: "WV - WEST VIRGINIA", value: "WV" },
    { label: "WY - WYOMING", value: "WY" },
  ];
  
  export const getStates = (param) => {
    if (param === "to") {
      return [
        { label: "A1 - Any U.S. State", value: "A1" },
        { label: "A2 - Any Canadian Province", value: "A2" },
        { label: "A3 - Any U.S. & Canadian", value: "A3" },
        ...states,
      ];
    } else return states;
  };
  
  export const colorsWithStates = {
    yellow: {
      states: ["CA", "CT", "MA", "MD", "ME", "MT", "NE", "NH", "NJ", "NY", "RI", "SC", "VT"],
      color: "yellow",
      value: "#ccb006",
    },
    blue: {
      states: ["AL", "AR", "DC", "DE", "FL", "GA", "IA", "LA", "MS", "NV", "TN", "UT"],
      color: "blue",
      value: "#0000FF",
    },
    green: {
      states: ["IL", "IN", "KS", "KY", "OH", "OK", "SD", "VA", "WA", "WI", "WV", "WY"],
      color: "green",
      value: "#008000",
    },
    pink: {
      states: ["AZ", "CO", "ID", "MI", "MN", "MO", "NC", "ND", "NM", "OR", "PA", "TX"],
      color: "pink",
      value: "#ff0894",
    },
    gray: {
      states: ["MI", "NC", "SC", "MT", "NE", "TN", "LA", "VA", "WV"],
      color: "gray",
      value: "#808080",
    },
    cyan: {
      states: ["MD", "ME", "DC", "DE", "KS", "WY", "MO", "ND", "TN"],
      color: "cyan",
      value: "#00bfa8",
    },
  };
  