import { useState, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Slide, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import formatDate from "../../utils/formatDate";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import PublishIcon from "@mui/icons-material/Publish";
import CustomPayloadField from "./PayloadField";
import CustomDimensionTextFields from "./DimensionTextFields";
import CustomLocationField from "./LocationField";
import CustomNotesField from "./NotesField";
import CustomDateTimePicker from "./DateTimePicker";
import CustomCheckBoxAndTextField from "./CheckBoxAndTextField";
import SignatureEdit from "./SignatureEdit";
const Transition = forwardRef((props, ref) => (
	<Slide
		direction="up"
		ref={ref}
		{...props}
	/>
));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
	"& .MuiDialogTitle-root": {
		position: "relative",
		padding: theme.spacing(2),
		"& .MuiIconButton-root": {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	},
}));

function BootstrapDialogTitle({ children, onClose, ...other }) {
	return (
		<DialogTitle {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label="close"
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	);
}

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func,
};

function EditModal({ data, open, setOpen, handleSubmit, handleDelete }) {
	const [changedFormValues, setChangedFormValues] = useState({});

	const handleChange = (key, value) => {
		setChangedFormValues((prevChangedValues) => ({
			...prevChangedValues,
			[key]: value,
		}));
	};

	const handleClose = () => {
		setChangedFormValues({});
		setOpen(false);
	};

	return (
		<div>
			<BootstrapDialog
				PaperProps={{
					variant: "outlined",
					elevation: 0,
				}}
				fullWidth
				maxWidth="md"
				TransitionComponent={Transition}
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
			>
				<BootstrapDialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
				>
					{data.Unit} changed by the {data.updatedBy} on {formatDate(data.updatedDate)}
				</BootstrapDialogTitle>
				<DialogContent dividers>
					<Grid
						container
						spacing={2}
						sx={{ userSelect: "none" }}
					>
						<CustomCheckBoxAndTextField
							data={{ Available: data.Available, Name: data.Name, hold: data.hold, Unit: data.Unit }}
							changedFormValues={{ Available: changedFormValues.Available, hold: changedFormValues.hold }}
							handleChange={handleChange}
						/>
						<CustomDateTimePicker
							data={{ Date: data.Date }}
							changedFormValues={{ Date: changedFormValues.Date }}
							handleChange={handleChange}
						/>
						<CustomNotesField
							data={{ notes: data.notes, bidNotes: data.bidNotes }}
							handleChange={handleChange}
						/>
						<CustomLocationField
							data={{ City: data.City, State: data.State }}
							changedFormValues={{ State: changedFormValues.State }}
							handleChange={handleChange}
						/>
						<CustomDimensionTextFields
							data={{ dims: data.dims }}
							handleChange={handleChange}
						/>
						<CustomPayloadField
							data={{ payload: data.payload, TYPE: data.TYPE }}
							changedFormValues={{ Type: changedFormValues.Type }}
							handleChange={handleChange}
						/>
						<SignatureEdit
							data={{ plateNumber: data.plateNumber, driverNumber: data.driverNumber, additionalInfo: data.additionalInfo }}
							handleChange={handleChange}
						/>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => handleDelete(data)}
						variant="outlined"
						startIcon={<PersonRemoveIcon />}
						color="error"
					>
						Delete Unit
					</Button>
					<Button
						startIcon={<PublishIcon />}
						disabled={Object.keys(changedFormValues).length === 0}
						variant="outlined"
						color="success"
						onClick={() => handleSubmit({ ...changedFormValues, _id: data._id }, setChangedFormValues)}
					>
						Submit
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
}

EditModal.propTypes = {
	data: PropTypes.object.isRequired,
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
};

export default memo(EditModal);
