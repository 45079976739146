import axiosInstance from "../utils/axios";

const init = () => {
    return axiosInstance.get("/login").then((response) => { return response?.data } )
};

const submit = (data) => {
    return axiosInstance.post("/login", data).then((response) => { return response?.data } )
};


export default {init, submit};