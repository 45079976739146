import WidgetsIcon from "@mui/icons-material/Widgets";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
const cards = [
  {
    key: "loadsTotal",
    title: "Total loads",
    icon: WidgetsIcon,
    color: "linear-gradient(60deg, rgba(94,53,177,1) 0%, rgba(3,155,229,1) 100%)",
  },
  {
    key: "bidsToday",
    title: "Your bids",
    icon: AttachMoneyIcon,
    color: "linear-gradient(60deg, rgba(245,0,87,1) 0%, rgba(255,138,128,1) 100%)",
  },
  {
    key: "bidsTotal",
    title: "Total bids",
    icon: TrendingUpIcon,
    color: "linear-gradient(60deg, rgba(67,160,71,1) 0%, rgba(255,235,59,1) 100%)",
  },
  {
    key: "availableUnits",
    title: "Available Units",
    icon: LocalShippingIcon,
    color: "linear-gradient(60deg, rgba(251,140,0,1) 0%, rgba(255,202,41,1) 100%)",
  },
];

export default cards;
