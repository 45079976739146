import { Select, MenuItem, FormControl } from '@mui/material';

function SingleSelectFilterInput({ item, applyValue }) {
  const handleChange = (event) => {
    applyValue({ ...item, value: event.target.value });
  };

  return (
    <FormControl fullWidth>
      <Select
        value={item.value || ''}
        onChange={handleChange}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="all">All</MenuItem>
        <MenuItem value="Hold">Hold</MenuItem>
        <MenuItem value="Available">Available</MenuItem>
        <MenuItem value="NotAvailable">Not Available</MenuItem>
      </Select>
    </FormControl>
  );
}

export default SingleSelectFilterInput;