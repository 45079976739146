import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "../../../components/Loads Table/Table";
const LoadsCard = ({ data, setOpen, loading, setLoading, setBidInfo }) => (
  <Card variant="elevation" sx={{ minWidth: 275 }}>
    <CardContent>
      <Typography  sx={{ userSelect: "none" }} align="left" variant="h5" gutterBottom>
        Loads
      </Typography>
      <Table data={data} setOpen={setOpen} loading={loading} setLoading={setLoading} setBidInfo={setBidInfo} />
    </CardContent>
  </Card>
);

export default LoadsCard;
