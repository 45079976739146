import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import { default as MCard } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import CompanyTabs from "../Tabs";
export default function OptionsCard({ company, handleChange, handleCreate }) {
	return (
		<Box
			sx={{
				marginTop: "1.5rem",
				marginBottom: "1.5rem",
			}}
		>
			<MCard variant="elevation">
				<CardContent>
					<Grid
						container
						rowSpacing={1}
						spacing={2}
						sx={{ userSelect: "none" }}
					>
						<Grid
							item
							xs={1}
						>
							<Button
                                onClick={() => {
                                    handleCreate()
                                }}
								fullWidth
								size="large"
								variant="outlined"
								startIcon={<LibraryAddIcon />}
								sx={{
									minHeight: "100%",
								}}
							>
								New Invoice
							</Button>
						</Grid>
						<Grid
							item
							xs={9}
						>
							<CompanyTabs
								value={company}
								handleChange={handleChange}
							/>
						</Grid>
					</Grid>
				</CardContent>
			</MCard>
		</Box>
	);
}
