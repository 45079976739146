import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "../../components/Card/Card";
import Cards from "../../configs/vehiclesCards";
import vehicles from "../../api/vehicles";
import SearchCard from "./Cards/Options";
import Table from "../../components/Vehicles Table/Table";
import Try from "../../utils/tryPromise";
import EditModal from "../../components/Edit Unit/Modal";

const Vehicles = ({ lastJsonMessage }) => {
	const [data, setData] = useState([]);
	const [modalData, setModalData] = useState({});
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [open, setOpen] = useState(false);

	const refresh = async () => {
		await Try(
			async () => {
				setData(await vehicles.get());
			},
			"vehicles",
			setLoading
		);
	};

	const handleEditSubmit = async (data, setChangedFormValues) => {
		await Try(
			async () => {
				const response = await vehicles.edit(data);
				if (response) {
					setOpen(false);
					setChangedFormValues({});
				}
			},
			"editUnit",
			setLoading
		);
	};

	const handleDelete = async (data) => {
		const confirmDelete = window.confirm(`Are you certain you wish to permanently remove Unit ${data.Unit}?`);
		if (!confirmDelete) return;

		await Try(
			async () => {
				const response = await vehicles.remove(data);
				if (response) setOpen(false);
			},
			"deleteUnit",
			setLoading
		);
	};

	const handleAdd = async (Unit) => {
		await Try(
			async () => {
				const response = await vehicles.add(Unit);
				if (response) setOpen(false);
			},
			"addUnit",
			setLoading
		);
	};

	useEffect(() => {
		refresh();
	}, [lastJsonMessage]);

	const renderCards = () =>
		Cards.map((card) => (
			<Grid
				sx={{ userSelect: "none" }}
				item
				xs={3}
				key={card.key}
			>
				<Card
					title={card.title}
					value={data[card.key] || 0}
					iconPerformance={card.icon}
					color={card.color}
				/>
			</Grid>
		));

	return (
		<>
			<EditModal
				handleDelete={handleDelete}
				handleSubmit={handleEditSubmit}
				open={open}
				setOpen={setOpen}
				data={modalData}
			/>
			<Grid
				container
				rowSpacing={1}
				spacing={12}
			>
				<Grid
					item
					xs={6}
				>
					<SearchCard
						handleAdd={handleAdd}
						setSearchTerm={setSearchTerm}
						loading={loading}
					/>
				</Grid>
				{renderCards()}
				<Grid
					item
					xs={12}
				>
					<Table
						loading={loading}
						searchTerm={searchTerm}
						data={data.units}
						setOpen={setOpen}
						setModalData={setModalData}
						mode="vehicles"
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default Vehicles;
