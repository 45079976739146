import { TableCell, TableRow, Button, Typography, Tooltip } from "@mui/material";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import handleDistance from "../../utils/handleDistance";
import { memo } from "react";

function LocationCell({ location, time }) {
  return (
    <>
      <Typography fontSize="14px" fontWeight="bold" color={"#55b5f2"}>
        {location}
      </Typography>
      <Typography fontSize="14px" fontWeight="bold" color="#f44336">
        {time}
      </Typography>
    </>
  );
}

function VehicleCell({ vehicle, miles }) {
  return (
    <>
      {vehicle} <br />
      <Typography fontWeight="bold" color="#f44336">
        {miles}
      </Typography>
    </>
  );
}

function PcsWeightCell({ pcs, weight }) {
  return (
    <>
      {pcs} <br /> {weight}
    </>
  );
}

function PostCell({ posted, expires }) {
  return (
    <>
      <span>{posted}</span> <br />
      <span>{expires}</span>
    </>
  );
}

function RateNotesCell({ rate, notes }) {
  return (
    <>
      {rate !== "NONE" && <Typography style={{ color: "#0aff1e" }}>{rate}</Typography>}
      <Typography style={{ fontSize: "12px" }}> {notes} </Typography>
    </>
  );
}

function DistanceCell({ loading, item, setLoading, setBidInfo, setOpen }) {
  return (
    <Tooltip placement="top" arrow title="Look for Units & Information">
      <span>
      <Button disabled={loading} onClick={() => handleDistance(item, setLoading, setBidInfo, setOpen)} variant="outlined" size="large">
        <LocalShippingIcon />
      </Button>
      </span>
    </Tooltip>
  );
}

function DataRow({ item, loading, setBidInfo, setLoading, setOpen }) {
  const { broker, from, to, vehicle, miles, pcs, weight, post, rate, notes } = item;

  return (
    <TableRow hover sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">{broker}</TableCell>
      <TableCell><LocationCell {...from} /></TableCell>
      <TableCell><LocationCell {...to} /></TableCell>
      <TableCell><VehicleCell vehicle={vehicle} miles={miles} /></TableCell>
      <TableCell><PcsWeightCell pcs={pcs} weight={weight} /></TableCell>
      <TableCell><PostCell posted={post.posted} expires={post.expires} /></TableCell>
      <TableCell sx={{ width: "15%" }}><RateNotesCell rate={rate} notes={notes} /></TableCell>
      <TableCell>
        <DistanceCell loading={loading} item={item} setLoading={setLoading} setBidInfo={setBidInfo} setOpen={setOpen} />
      </TableCell>
    </TableRow>
  );
}

export default memo(DataRow);
