import React from "react";
import Typography from "@mui/material/Typography";


const renderStateUnits = (units) => {
  return units.map((unit) => <span  key={unit}>{unit}, </span>);
};

const renderStates = (states) => {
  return Object.entries(states).map(([state, units]) => {
    return (
      <div key={state} >
        <hr />
        <p style={{ fontWeight: "bold" }}>{state}</p>
        {renderStateUnits(units)}
        <hr />
      </div>
    );
  });
};

const RenderColors = ({ data, color }) => {
  return (
    <div key={color} style={{ borderRadius: "1%", fontSize: "16px" }}>
      <Typography align="center"color={color} variant="h5" gutterBottom sx={{fontWeight: "bolder"}}>
      {color.toUpperCase()}
      </Typography>
      <hr />
      {renderStates(data)}
    </div>
  );
};

const Display = ({ data, color }) => {
  if (!data || Object.keys(data).length === 0) return (
    <Typography align="center"color={color} variant="h5" gutterBottom sx={{fontWeight: "bolder"}}>
    </Typography>
  );
  return (
    <>
      <RenderColors data={data} color={color} />
    </>
  );
};

export default Display;
