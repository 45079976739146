import { default as MCard } from "@mui/material/Card";
import { default as MButton } from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const getCount = (data) => {
  if (!data || Object.keys(data).length === 0) return 0;
  return Object.values(data).reduce((count, arr) => count + arr.length, 0);
};

export default function Button({ title, value, color }) {
  return (
    <MCard
      variant="outlined"
      sx={{
        backgroundColor: "transparent",
        borderWidth: "2px",
        borderColor: value,
        transition: "transform 0.3s, box-shadow 0.3s, background 0.3s ease",
        backgroundPosition: "0 100%",
        backgroundSize: "200% 200%",
      }}
    >
      <CardContent
        component={MButton}
        sx={{ minHeight: "100%", minWidth: "100%", color: value }}
      >
        <Typography sx={{ fontWeight: "bold" }} align="right" variant="h5" color={value} component="div">
          {getCount(title)}
        </Typography>
      </CardContent>
    </MCard>
  );
}
