import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Table from "./Table";
import Pie from "./Pie";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Leaderboard({ topToday, topTotal }) {
  return (
    <>
      <Grid sx={{ marginTop: "50px" }} item xs={8}>
        <Card variant="elevation" sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography align="left" variant="h5" gutterBottom>
              Leaderboard
            </Typography>
            <Table data={topTotal} />
          </CardContent>
        </Card>
      </Grid>
      <Grid display="flex" justifyContent={"center"} item xs={4} sx={{ marginTop: "50px" }}>
        <Card sx={{ minWidth: "100%", minHeight: "100%" }}>
          <CardContent >
            <Typography align="left" variant="h5" gutterBottom>
              Today Statistics
            </Typography>
            <Pie data={topToday} />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}
