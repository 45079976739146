import { useState } from "react";
import loads from "../../api/loads";
import Try from "../../utils/tryPromise";
import SearchCard from "./Cards/Options";
import LoadsCard from "./Cards/Loads";
import Modal from "../../components/Bid/Info/Modal";

const FindLoad = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [bidInfo, setBidInfo] = useState([]);
  const [data, setData] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const handleSearch = async (event) => {
    event.preventDefault();
    await Try(
      async () => {
        const response = await loads.find({ from, to });
        if (response) setData(response);
      },
      "searchLoad",
      setLoading
    );
  };

  return (
    <>
      <Modal open={open} bidInfo={bidInfo} setOpen={setOpen} />
      <SearchCard setFrom={setFrom} setTo={setTo} loading={loading} handleSearch={handleSearch} />
      <LoadsCard data={data} setOpen={setOpen} loading={loading} setLoading={setLoading} setBidInfo={setBidInfo} />
    </>
  );
};

export default FindLoad;
