import React, { useContext, useState, useEffect, memo } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import isOpen from "../../context/Drawer";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import appNavs from "../../configs/navigations";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logo from "../../assets/logo.svg";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = () => {
  const open = useContext(isOpen);
  const { pathname } = useLocation();
  const [header, setHeader] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      global.token.setToken("");
    }
  };

  useEffect(() => {
    const matchingElement = appNavs.find((element) => element.to === pathname);

    if (matchingElement) setHeader(matchingElement.title);
  }, [pathname]);

  return (
    <>
      <AppBar position="fixed" open={open} sx={{ userSelect: "none" }}>
        <Toolbar disableGutters>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, marginLeft: 1 }}>
            {!open ? (
              <div style={{ display: "flex", alignItems: "center", fontWeight: "bold" }}>
                <img src={Logo} alt="Logo" style={{ height: "50px", margin: 0 }} />
                {header.toUpperCase()} 
              </div>
            ) : (
              <div style={{ fontWeight: "bold" }}>{header.toUpperCase()}</div>
            )}
          </Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={onClickLogout}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Log out
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default memo(Header);
