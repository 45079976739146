import { useState, useEffect } from "react";
import api from "../../api/history";
import BidTable from "./Bid Table/Table";
import SimpleDialogDemo from "./Dialog";
import Try from "../../utils/tryPromise";
const History = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [loading, setLoading] = useState(false);
  const [_id, set_id] = useState(null);


  const handleClickOpen = (id) => {
    set_id(id)
    setOpen(true);
  };

  const handleSubmit = async (data) => {
    await Try(async () => {
      const response = await api.insert({_id, ...data});
      if (response) {
        setOpen(false)
        setSelectedValue()
      }
    }, "historySelect", setLoading);
  };

  useEffect(() => {
    (async () => {
      setData(await api.getHistory());
    })();
  }, []);

  return (
    <>
    <SimpleDialogDemo open={open} setOpen={setOpen} selectedValue={selectedValue} setSelectedValue={setSelectedValue} handleSubmit={handleSubmit}/>
      <BidTable data={data} handleClickOpen={handleClickOpen} loading={loading} />
    </>
  );
};

export default History;
