import axiosInstance from "../utils/axios";

const get = () => {
	return axiosInstance.get("/payslip").then((response) => {
		return response?.data;
	});
};

const post = (data) => {
	return axiosInstance
		.post("/payslip", data, {
			responseType: "blob",
		})
		.then((response) => {
			return response?.data;
		});
};

export default { get, post };
