import React from "react";
import { colorsWithStates } from "../../../configs/states";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography  from "@mui/material/Typography";
const ColorButtons = ({ from, setFrom }) => {
  const isColorSelected = (color) => from.some((state) => colorsWithStates[color].states.includes(state));

  const handleButtonClick = (color) => {
    const statesToAdd = colorsWithStates[color].states; // Get the states based on the selected color
    const newFrom = [...new Set([...from, ...statesToAdd])]; // Update the 'from' state

    // If the color is currently selected, remove the states from the 'from' state
    if (isColorSelected(color)) {
      const statesToRemove = colorsWithStates[color].states;
      const filteredFrom = from.filter((state) => !statesToRemove.includes(state));
      setFrom(filteredFrom);
    } else {
      setFrom(newFrom);
    }
  };

  return (
    <>
      {Object.entries(colorsWithStates).map(([color, { value }]) => {
        return (
          <Grid key={color} item xs={4}>
            <Card
              variant="outlined"
              sx={{
                backgroundColor: "transparent",
                borderWidth: "1px",
                borderColor: value,
                boxShadow: isColorSelected(color)
                  ? "0 0 25px rgba(255, 255, 255, 0.7)"
                  : "",
                margin: "0.5rem",
                "&:hover": {
                  boxShadow: `0 0px 25px ${value}`,
                  transform: "scale(1.1)",
                },
              }}
            >
              <CardContent
              // disableElevation
              // size="small"
                sx={{ minWidth: "100%", maxHeight: "5px", m: 0, p: 1, color: value }}
                component={Button}
                onClick={() => handleButtonClick(color)}
              >
                <Typography mt="1rem">{color} </Typography> 
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </>
  );
};

export default ColorButtons;
