import { useState } from "react";
import SearchCard from "./Cards/Options";
import Table from "../../components/Vehicles Table/Table";
import loads from "../../api/loads";
import Try from "../../utils/tryPromise";
const ClosestUnits = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [location, setLocation] = useState("");

  const handleSearch = async (event) => {
    event.preventDefault();
    await Try(async () => {
      const response = await loads.bid(location);
      if (response) setData(response.nearbyDrivers);
    }, "closestUnits", setLoading);
  };

  return (
    <>
      <SearchCard setLocation={setLocation} loading={loading} handleSearch={handleSearch} />
      <Table data={data} mode="closestUnits" />
    </>
  );
};

export default ClosestUnits;
