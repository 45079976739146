const vehicles = [
    {
      label: "CARGO VAN",
      value: "CARGO VAN",
    },
    {
      label: "SPRINTER",
      value: "SPRINTER",
    },
    {
      label: "LARGE STRAIGHT",
      value: "LARGE STRAIGHT",
    },
    {
      label: "SMALL STRAIGHT",
      value: "SMALL STRAIGHT",
    },
  ]


export default vehicles