// App.js
import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
} from '@mui/material';
import EditUser from './EditUser';
import Try from '../../utils/tryPromise';
import api from '../../api/users';
import AddUserDialog from './AddUserDialog'; // Import the AddUserDialog component

function App() {
  const [data, setData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const refresh = async () => {
    await Try(
      async () => {
        const response = await api.get();
        setData(response);
      },
      'users',
      setLoading
    );
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleSelectUser = (event) => {
    setSelectedUserId(event.target.value);
  };

  const selectedUser = data.users?.find((user) => user._id === selectedUserId);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddUser = async (newUser) => {
    console.log(newUser)
    await Try(
      async () => {
        await api.put(newUser);
        refresh();
        handleClose();
      },
      'addUser',
      setLoading
    );
  };


  const handleSubmit = async (data) => {
    
    console.log(data)
    await Try(
      async () => {
        await api.post(data);
        refresh();
        handleClose();
      },
      'editUser',
      setLoading
    );
  };


  const handleDelete = async (data) => {
    await Try(
      async () => {
        await api.del(data);
        refresh();
        handleClose();
      },
      'removeUser',
      setLoading
    );
  };

  return (
    <Container>
      <Grid container spacing={3}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom>
            Select a User to Edit
          </Typography>
        </Grid>

        {/* User Selection Section */}
        <Grid item xs={12} md={10}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="user-select-label">User</InputLabel>
            <Select
              labelId="user-select-label"
              value={selectedUserId}
              onChange={handleSelectUser}
            >
              {data.users?.map((user) => (
                <MenuItem key={user._id} value={user._id}>
                  {user.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Add User Button Section */}
        <Grid item xs={12} md={2} display="flex" alignItems="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpen}
            fullWidth
          >
            Add New User
          </Button>
        </Grid>

        {/* AddUserDialog Section */}
        <AddUserDialog
          open={open}
          handleClose={handleClose}
          handleAddUser={handleAddUser}
        />

        {/* Edit User Section */}
        {selectedUser && (
          <Grid item xs={12}>
            <EditUser data={selectedUser} handleSubmit={handleSubmit} handleDelete={handleDelete} />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default App;
