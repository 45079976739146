import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import { memo } from "react";
const Search = ({label, setSearchTerm, icon = false, placeholder = ""}) => {

  return (
      <TextField
        label={label}
        autoFocus
        placeholder={placeholder}
        fullWidth
        onChange={(e) => setSearchTerm(e.target.value.trim())}
        InputProps={icon ? {

          startAdornment: (
            <InputAdornment position="start">
                <SearchIcon/>
            </InputAdornment>
          ),
        } : {}}
      />
  );
};

export default memo(Search);