import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Header = () => {
    return (
        <TableHead  sx={{ userSelect: "none" }}>
          <TableRow>
            <TableCell >Company</TableCell>
            <TableCell >MC</TableCell>
            <TableCell >Credit Rate</TableCell>
            <TableCell >Description</TableCell>
            <TableCell >City, State</TableCell>
            <TableCell >Address</TableCell>
            <TableCell >Phone</TableCell>
          </TableRow>
        </TableHead>
    );
};

export default Header;