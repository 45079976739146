import { toast } from "react-toastify";
import ToastConfig from "../configs/toast";
import StatusMessages from "../configs/status";

const Try = async (func, status, setLoading) => {
  const { pending, success, error } = StatusMessages[status];
  
  const renderPending = () => {
    if (setLoading) setLoading(true);
    return pending;
  };

  const renderSuccess = () => {
    if (setLoading) setLoading(false);
    return success;
  };

  const renderError = ({ data }) => {
    if (setLoading) setLoading(false);
    return data?.response?.data?.error ?? error;
  };

  toast.promise(func, {
    pending: { render: renderPending },
    success: { render: renderSuccess },
    error: { render: renderError },
  }, ToastConfig());
};

export default Try;
