import React, { useState, useEffect } from "react";
import {
	TextField,
	Button,
	Container,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	Switch,
	FormControlLabel,
	Grid,
} from "@mui/material";

function EditUser({ data, handleSubmit, handleDelete }) {
	const [formData, setFormData] = useState({
		name: "",
		password: "",
		bidsTotal: 0,
		bidsToday: 0,
		gass: "",
		role: "",
		disabled: false,
		_id: "",
	});

	const [initialData, setInitialData] = useState({});

	// Set form data and initial data when the data prop changes
	useEffect(() => {
		setFormData(data);
		setInitialData(data);
	}, [data]);


	// Handle input changes
	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	// Handle toggle change for the disabled status
	const handleToggleChange = (e) => {
		setFormData({
			...formData,
			disabled: e.target.checked,
		});
	};

	// Function to regenerate a random password
	const regeneratePassword = () => {
		const newPassword = Math.random().toString(36).slice(-10).toUpperCase();
		setFormData({
			...formData,
			password: newPassword,
		});


    copyToClipboard(newPassword)
	};


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        alert('Password copied to clipboard!');
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  };

	return (
		<Container>
			<Typography variant="h5" component="h2" gutterBottom>
				Edit User
			</Typography>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Name"
						name="name"
						value={formData.name}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Password"
						name="password"
						type="text"
						value={formData.password}
						onChange={handleChange}
						fullWidth
          disabled

						margin="normal"
					/>
					<Button
						onClick={regeneratePassword}
						variant="outlined"
						color="primary"
						fullWidth
					>
						Regenerate Password
					</Button>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						label="Mail"
						name="gass"
						value={formData.gass}
						onChange={handleChange}
						fullWidth
						margin="normal"
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl fullWidth margin="normal">
						<InputLabel id="role-select-label">Role</InputLabel>
						<Select
							labelId="role-select-label"
							name="role"
							value={formData.role}
							onChange={handleChange}
						>
							<MenuItem value="admin">Admin</MenuItem>
							<MenuItem value="dispatcher">Dispatcher</MenuItem>
							<MenuItem value="support">Support</MenuItem>
							<MenuItem value="coordinator">Coordinator</MenuItem>
							<MenuItem value="teamlead">Team Lead</MenuItem>
							<MenuItem value="Trainer">Trainer</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12}>
					<FormControlLabel
						control={
							<Switch
								checked={formData.disabled}
								onChange={handleToggleChange}
								name="disabled"
								color="primary"
							/>
						}
						label="User Disabled"
					/>
				</Grid>
        <Grid item xs={12} sm={6}>
    <Button
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => {
            // Determine which fields have changed compared to the initial data
            const changedFields = Object.keys(formData).reduce((acc, key) => {
                if (key !== "_id" && formData[key] !== initialData[key]) {
                    acc[key] = formData[key];
                }
                return acc;
            }, {});

            // Include _id only if other fields have changed
            if (Object.keys(changedFields).length > 0) {
                changedFields._id = formData._id;
            }

            // Call the handleSubmit function with the changed fields
            handleSubmit(changedFields);
        }}
    >
        Save Changes
    </Button>
</Grid>

				<Grid item xs={12} sm={6}>
					<Button variant="outlined" color="error" fullWidth onClick={() => handleDelete(formData._id)}>
						Delete User
					</Button>
				</Grid>
			</Grid>
		</Container>
	);
}

export default EditUser;
