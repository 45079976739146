import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { colorsWithStates } from "../../configs/states";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";
import getColoredAreas from "../../api/coloredAreas";
import groupUnitsByColor from "../../utils/groupUnitsbyColor";
import Button from "./components/Button";
import Display from "./components/Display";

const ColoredAreas = () => {
  const [data, setData] = useState({});
  useEffect(() => {
    (async () => {
      const response = await getColoredAreas();

      response && setData(groupUnitsByColor(response, colorsWithStates));
    })();
  }, []);
  useEffect(() => {}, []);

  return (
    <>
      <Card variant="elevation" sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ userSelect: "none" }} align="left" variant="h5" gutterBottom>
            Colors
          </Typography>
          <Grid container rowSpacing={1} spacing={12} sx={{ userSelect: "none" }}>
            {Object.values(colorsWithStates).map((colorData) => {
              const { color, value } = colorData;
              return (
                <Grid item xs={4} key={color}>
                  <Button color={color} title={data[color]} value={value} />
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
      <Grid container spacing={12} sx={{ userSelect: "none" }}>
        {Object.values(colorsWithStates).map((colorData) => {
          const { color } = colorData;
          return (
            <Grid key={color} item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Card
                variant="elevation"
                align={"center"}
                sx={{
                  minWidth: "100%",
                  minHeight: "100%",
                  mt: "1.5rem",
                  boxShadow: `0 0px 10px ${color}`,
                }}
              >
                <CardContent>
                  <Display data={data[color]} color={color} />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ColoredAreas;
