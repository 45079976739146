import {
  BsBox2Heart,
  BsTruck,
  BsPatchCheck,
  BsHouseDoor,
  BsSearch,
  BsClockHistory,
} from "react-icons/bs";
import { RiTeamLine } from "react-icons/ri";
import { LiaCitySolid } from "react-icons/lia";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { CiMoneyBill } from "react-icons/ci";
import { FaUsersGear } from "react-icons/fa6";


const appNavs = [
  {
    key: "home",
    icon: <BsHouseDoor />,
    title: "Home",
    to: "/",
  },
  {
    key: "loads",
    icon: <BsBox2Heart />,
    title: "Loads",
    to: "/loads",
  },
  {
    key: "vehicles",
    icon: <BsTruck />,
    title: "Vehicles",
    to: "/vehicles",
  },
  {
    key: "checkBroker",
    icon: <BsPatchCheck />,
    title: "Check Broker",
    to: "/checkBroker",
  },
  {
    key: "closestUnits",
    icon: <RiTeamLine />,
    title: "Closest Units",
    to: "/closestUnits",
  },
  {
    key: "findLoad",
    icon: <BsSearch />,
    title: "Find Load",
    to: "/findLoad",
  },
  {
    key: "invoices",
    icon: <LiaFileInvoiceDollarSolid  />,
    title: "Invoices",
    to: "/invoices",
  },
  {
    key: "history",
    icon: <BsClockHistory />,
    title: "Bid History",
    to: "/history",
  },
  {
    key: "coloredStates",
    icon: <LiaCitySolid />,
    title: "Colored Areas",
    to: "/coloredStates",
  },
  {
    key: "payslip",
    icon: <CiMoneyBill />,
    title: "Payslip",
    to: "/payslip",
    admin: true
  },
  {
    key: "users",
    icon: <FaUsersGear />,
    title: "Users",
    to: "/users",
    admin: true
  },
];

export default appNavs;
