import handleColor from "../../../utils/handleColor";
import handleCopyText from "../../../utils/handleCopyText";
import formatDate from "../../../utils/formatDate";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PaidIcon from "@mui/icons-material/Paid";
import {  TableCell,  TableRow,  Button, Tooltip, } from "@mui/material";
import { memo } from "react";
const DriverRow = ({ driver, index, load, broker, setRateModal, setRateData }) => (
    <TableRow hover key={driver._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell size="medium">{driver.Unit}</TableCell>
      <TableCell>{driver.Name}</TableCell>
      <TableCell>
        <span style={{ color: handleColor(driver.Date, "time") }}>{formatDate(driver.Date)}</span>
        <br />
        <span style={{ color: "orange", fontSize: "12px" }}>{driver.bidNotes}</span>
      </TableCell>
      <TableCell>
        <span style={{ color: handleColor(driver.distance, "distance") }}>{driver.distance}</span>
      </TableCell>
      <TableCell>
        {driver.City}, {driver.State}
      </TableCell>
      <TableCell>{driver.TYPE}</TableCell>
      <TableCell>
        {driver.dims} <br /> {driver.payload}
      </TableCell>
      <TableCell>
        {broker.dimensions} <br /> {load.weight} x {load.pcs}
      </TableCell>
      <TableCell>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Tooltip placement="top" followCursor arrow title="Copy Load Information for Driver">
            <span>
            <Button onClick={() => handleCopyText.load(driver, load, broker)} variant="outlined" size="small">
              <ContentCopyIcon />
            </Button>
            </span>
          </Tooltip>
          <Tooltip placement="top" followCursor arrow title="Open Bid Window">
            <span>
            <Button
              onClick={() => {
                setRateData({ unit: driver.Unit, orderNo: load.orderNo, deadmiles: driver.distance, driverType: driver.TYPE });
                setRateModal(true);
              }}
              variant="outlined"
              color="success"
              size="small"
            >
              <PaidIcon />
            </Button>
            </span>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
  


  export default memo(DriverRow);