import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import MButton from "@mui/material/Button";
const Button = ({ loading, handleSearch, title }) => {
  return (
    <MButton
      disabled={loading}
      type="submit"
      fullWidth
      size="large"
      onClick={handleSearch}
      variant="outlined"
      startIcon={<SearchIcon />}
      sx={{
        minHeight: "100%",
      }}
    >
      {title ?? "Search"}
    </MButton>
  );
};

export default Button;
