const StatusMessages = {
	addUnit: {
		pending: "Creating unit...",
		success: "Unit created successfully.",
		error: "Unit creation failed.",
	},
	vehicles: {
		pending: "Retrieving vehicles...",
		success: "Vehicles retrieved successfully.",
		error: "Failed to retrieve vehicles.",
	},
	deleteUnit: {
		pending: "Deleting unit...",
		success: "Unit deleted successfully.",
		error: "Unit deletion failed.",
	},
	editUnit: {
		pending: "Updating unit details...",
		success: "Unit details updated successfully.",
		error: "Failed to update unit details.",
	},
	login: {
		pending: "Logging in...",
		success: "Logged in successfully.",
		error: "Login failed.",
	},
	bid: {
		pending: "Submitting bid...",
		success: "Bid submitted successfully.",
		error: "Bid submission failed.",
	},
	load: {
		pending: "Fetching load details...",
		success: "Load details fetched successfully.",
		error: "Failed to fetch load details.",
	},
	newLoad: {
		pending: "Retrieving new loads...",
		success: "New loads retrieved successfully.",
		error: "Failed to retrieve new loads.",
	},
	searchLoad: {
		pending: "Searching for loads...",
		success: "Loads found successfully.",
		error: "Load search failed.",
	},
	hideLoad: {
		pending: "Hiding loads...",
		success: "Loads hidden successfully.",
		error: "Failed to hide loads.",
	},
	unhideLoad: {
		pending: "Unhiding loads...",
		success: "Loads unhidden successfully.",
		error: "Failed to unhide loads.",
	},
	checkBroker: {
		pending: "Checking broker details...",
		success: "Broker details retrieved successfully.",
		error: "Failed to retrieve broker details.",
	},
	closestUnits: {
		pending: "Locating nearest units...",
		success: "Nearest units located successfully.",
		error: "Failed to locate nearest units.",
	},
	historySelect: {
		pending: "Sending load to invoice...",
		success: "Load sent to invoice successfully.",
		error: "Failed to send load to invoice.",
	},
	invoices: {
		pending: "Retrieving invoices...",
		success: "Invoices retrieved successfully.",
		error: "Failed to retrieve invoices.",
	},
	createInvoice: {
		pending: "Creating invoice...",
		success: "Invoice created successfully.",
		error: "Invoice creation failed.",
	},
	deleteInvoice: {
		pending: "Deleting invoice...",
		success: "Invoice deleted successfully.",
		error: "Invoice deletion failed.",
	},
	editInvoice: {
		pending: "Updating invoice details...",
		success: "Invoice details updated successfully.",
		error: "Failed to update invoice details.",
	},
	copyDispatch: {
		pending: "Copying dispatch...",
		success: "Dispatch copied successfully.",
		error: "Failed to copy dispatch.",
	},
	users: {
		pending: "Retrieving users...",
		success: "Users retrieved successfully.",
		error: "Failed to retrieve users.",
	},
	payslip: {
		pending: "Downloading payslip...",
		success: "Payslip downloaded successfully.",
		error: "Failed to download payslip.",
	},
	addUser: {
		pending: "Adding user...",
		success: "User added successfully.",
		error: "Failed to add user.",
	},
	removeUser: {
		pending: "Removing user...",
		success: "User removed successfully.",
		error: "Failed to remove user.",
	},
	editUser: {
		pending: "Updating user...",
		success: "User updated successfully.",
		error: "Failed to update user.",
	},
};

export default StatusMessages;
