import axiosInstance from "../utils/axios";

const getHistory = () => {
  return axiosInstance.get("/history").then((response) => {
    return response?.data;
  });
};


const insert = (data) => {
  return axiosInstance.post("/history", data ).then((response) => {
    return response?.data;
  });
};

export default {getHistory, insert};
