import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Header = () => {
    return (
        <TableHead  sx={{ userSelect: "none" }}>
          <TableRow>
            <TableCell >Order #</TableCell>
            <TableCell >Unit</TableCell>
            <TableCell >Broker & Date</TableCell>
            <TableCell >From</TableCell>
            <TableCell >To</TableCell>
            <TableCell >Deadmiles</TableCell>
            <TableCell >Gass Rate</TableCell>
            <TableCell >LogiMax Rate</TableCell>
            <TableCell >Bidder</TableCell>
            <TableCell >Actions</TableCell>
          </TableRow>
        </TableHead>
    );
};

export default Header;