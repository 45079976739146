function base64UrlDecode(str) {
    // Replace non-URL characters from base64url
    str = str.replace(/-/g, '+').replace(/_/g, '/');
    // Decode base64
    return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}

function decodeJWT(token) {
    const parts = token.split('.');

    if (parts.length !== 3) {
        throw new Error('Invalid JWT token');
    }

    const header = JSON.parse(base64UrlDecode(parts[0]));
    const payload = JSON.parse(base64UrlDecode(parts[1]));

    return {
        header: header,
        payload: payload,
        signature: parts[2] // Signature is the third part, usually not decoded
    };
}

export default decodeJWT;