import axiosInstance from "../utils/axios";

const get = () => {
  return axiosInstance.get("/invoices").then((response) => {
    return response?.data;
  });
};


const create = (data) => {
  return axiosInstance.post("/invoices/new", data).then((response) => {
    return response?.data;
  });
};

const dispatch = (data) => {
  return axiosInstance.post("/invoices", data).then((response) => {
    return response?.data;
  }).catch(() => {
    return null
  });
};

const edit = (data) => {
  return axiosInstance.put("/invoices", data).then((response) => {
    return response?.data;
  });
};

const remove = (_id) => {
  return axiosInstance.delete("/invoices", { data: { _id: _id } }).then((response) => {
    return response?.data;
  });
};

export default { get, edit, remove, dispatch, create };
