import { Table, TableBody, TableContainer, Paper, } from "@mui/material";
import DataRow from "./Row"
import Header from "./Header";
import "../../styles/Loads Table Animation.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";




export default function DenseTable({ data, loading, setOpen, setLoading, setBidInfo }) {
  return (
    <TableContainer component={Paper}>
      <Table stickyHeader sx={{ minWidth: "100%" }} size="small">
        <Header />
        <TransitionGroup component={TableBody}>
          {data.map((item) => (
            <CSSTransition key={item._id} timeout={500} classNames="load">
              <DataRow item={item} loading={loading} setOpen={setOpen} setLoading={setLoading} setBidInfo={setBidInfo} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Table>
    </TableContainer>
  );
}
