import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import SelectComponent from "../Select/Select";
import TextField from "@mui/material/TextField";
import vehicles from "../../configs/vehicles";
import { memo } from "react";

const CustomPayloadField = ({data, changedFormValues, handleChange}) => {
    return (
        <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
        <Typography width={"4.5rem"} mt={1}>
          Payload
        </Typography>
        <TextField
          onChange={(e) => {
            handleChange("Payload", e.target.value);
          }}
          placeholder="None"
          hiddenLabel
          defaultValue={data.payload}
          size="small"
          type="number"
        />
        <SelectComponent
          title={"Type"}
          options={vehicles}
          selectedOptions={changedFormValues.Type ?? data.TYPE}
          setSelectedOptions={(vehicle) => {
            handleChange("Type", vehicle[0]);
          }}
        />
      </Grid>
    );
};

export default memo(CustomPayloadField);