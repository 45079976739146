// Components
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ColorButtons from "../Colored Buttons/Buttons";
import Timer from "../Timer/Timer";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const FiltersCard = ({ from, setFrom, handleAction }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleAcordion = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      sx={{
        minWidth: "100%",
        "&.Mui-expanded": {
          minHeight: "200px",
          maxHeight: "200px",
        },
      }}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ cursor: "pointer" }} onClick={() => toggleAcordion()} />}
        sx={{ cursor: "unset !important" }}
      >
        <Typography variant="h5" sx={{ width: "33%", flexShrink: 0 }}>
          Filters
        </Typography>
        <Timer handleAction={handleAction} />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container sx={{ userSelect: "none" }}>
          <ColorButtons from={from} setFrom={setFrom} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default FiltersCard;
