import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import IconButton from "@mui/material/IconButton";

const Timer = ({ handleAction }) => {
  const initialSeconds = 30;
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  // Add a state variable to track whether the window is focused or not.
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  // Listen for changes in window focus using the Page Visibility API.
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsWindowFocused(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let interval = null;

    if (isActive && isWindowFocused) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          if (prevSeconds > 0) {
            return prevSeconds - 1;
          } else {
            handleAction();
            setIsActive(true);
            setSeconds(initialSeconds);
            return initialSeconds;
          }
        });
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval); // Cleanup when component unmounts
  }, [isActive, handleAction, isWindowFocused]);

  return (
    <TextField
      label="Timer"
      size="small"
      value={seconds}
      sx={{ mr: 2 }}
      inputProps={{ readOnly: true, style: { textAlign: "center" } }}
      fullWidth
      InputProps={{
        endAdornment: <InputAdornment position="end">Sec</InputAdornment>,
        startAdornment: (
          <InputAdornment position="start">
            <IconButton size="small" onClick={() => setIsActive(!isActive)}>
              {isActive ? <PauseCircleFilledIcon /> : <PlayCircleFilledIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Timer;
