// CustomDateTimePicker.js
import React, { useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { Typography, Grid, Button } from "@mui/material";
import dayjs from "dayjs";
import { memo } from "react";

function CustomDateTimePicker({
  data,
  changedFormValues,
  handleChange,
  ...other
}) {
  const [dateState, setDateState] = useState(false);

  return (
    <Grid display="flex" gap={2} justifyContent={"left"} item xs={12} {...other}>
      <Typography width={"4.5rem"} mt={1}>
        Date
      </Typography>
      <DateTimePicker
        open={dateState}
        onChange={(value) => handleChange("Date", dayjs(value).unix())}
        onClose={() => setDateState(false)}
        slotProps={{ textField: { size: "small", onClick: () => setDateState(true) } }}
        ampm={false}
        value={changedFormValues.Date ? dayjs.unix(changedFormValues.Date) : dayjs.unix(data.Date)}
      />
      <Button onClick={() => handleChange("Date", dayjs().unix())} variant="outlined">Now</Button>
    </Grid>
  );
}

export default memo(CustomDateTimePicker);
