import { Card, CardContent, Typography, Grid, TextField, InputAdornment } from "@mui/material";

const Bid = ({ setBidData }) => {
  const updateRate = (item, newRate) => {
    setBidData((prevBidData) => ({
      ...prevBidData,
      [item]: {
        ...prevBidData[item],
        rate: newRate,
      },
    }));
  };

  // Function to update notes for a specific item
  const updateNotes = (item, newNotes) => {
    setBidData((prevBidData) => ({
      ...prevBidData,
      [item]: {
        ...prevBidData[item],
        notes: newNotes,
      },
    }));
  };

  return (
    <Card sx={{ minWidth: "100%", minHeight: "100%", mt: "1rem" }}>
      <CardContent>
        <Typography align="left" variant="h5" gutterBottom>
          Bid
        </Typography>
        <Grid container rowSpacing={2} spacing={3} mt={1} sx={{ userSelect: "none" }}>
          <Grid item xs={3}>
            <TextField
              inputProps={{
                min: 1,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              placeholder={"0"}
              size="small"
              type="number"
              fullWidth={true}
              label={"Gass Rate"}
              variant="outlined"
              onChange={(e) => {
                updateRate("gass", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              size="small"
              type="text"
              fullWidth={true}
              label={"Gass Notes"}
              variant="outlined"
              onChange={(e) => {
                updateNotes("gass", e.target.value.trim());
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              inputProps={{
                min: 1,
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              placeholder={"0"}
              size="small"
              type="number"
              fullWidth={true}
              label={"LogiMax Rate"}
              variant="outlined"
              onChange={(e) => {
                updateRate("lmux", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <TextField
              size="small"
              type="text"
              fullWidth={true}
              label={"LogiMax Notes"}
              variant="outlined"
              onChange={(e) => {
                updateNotes("lmux", e.target.value.trim());
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Bid;
