import { useEffect } from "react";
import ToastConfig from "../configs/toast";
import { toast } from "react-toastify";

const WebSocket = ({lastJsonMessage}) => {

  useEffect(() => {
    if (lastJsonMessage !== null && lastJsonMessage.mode === "VEHICLES") {
      toast.info(
        `${lastJsonMessage.type} UNIT: ${lastJsonMessage.unit} ${lastJsonMessage.oldState ? `from ${lastJsonMessage.oldState} to ${lastJsonMessage.newState}` : ""} by ${lastJsonMessage.user}`,
        ToastConfig()
      );
    }
  }, [lastJsonMessage]);

  return null;
};

export default WebSocket;
