import { useEffect, useRef, createRef } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";

const ITEM_HEIGHT = 75;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

export default function SelectComponent({ title, options, selectedOptions, setSelectedOptions, multiple = false, sx }) {
  const optionRefs = useRef(options.map(() => createRef()));

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event?.key?.length === 1) {
        const char = event.key.toUpperCase();
        const index = options.findIndex((option) => option.label.startsWith(char));
        if (index !== -1 && optionRefs?.current[index]?.current) {
          optionRefs.current[index].current.scrollIntoView({ block: "nearest" });
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [options]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOptions(multiple ? value : [value]);
  };

  const handleDeselectAll = () => {
    setSelectedOptions([]);
  };

  return (
    <div>
      <FormControl size="small" sx={{ display: "flex", ...sx }}>
        <InputLabel>{title}</InputLabel>
        <Select
        fullWidth
          multiple={multiple}
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label={title} />}
          renderValue={(selected) => (Array.isArray(selected) ? selected.join(", ") : selected.toString())}
          MenuProps={MenuProps}
          sx={{ display: "flex"}}
        >
          {multiple && selectedOptions.length > 0 && (
            <div>
              <Button sx={{ width: "100%" }} onClick={handleDeselectAll}>
                Deselect All
              </Button>
            </div>
          )}
          {options.map((option, index) => (
            <MenuItem key={option.value} value={option.value} ref={optionRefs.current[index]}>
              {multiple && (
                <Checkbox
                  sx={{ padding: "0.2rem", marginLeft: "0" }}
                  size="small"
                  checked={selectedOptions.indexOf(option.value) > -1}
                />
              )}
              <ListItemText primaryTypographyProps={{fontSize: 12}} primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
