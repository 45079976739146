import { useState, useEffect, useCallback } from "react";
import Grid from "@mui/material/Grid";
import Try from "../../utils/tryPromise";
import Action from "../../utils/handleActions";
import Modal from "../../components/Bid/Info/Modal";
import FiltersCard from "./Cards/Filters";
import OptionsCard from "./Cards/Options";
import LoadsCard from "./Cards/Loads";


const Loads = () => {
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState(["A1"]);
  const [types, setTypes] = useState([])
  const [data, setData] = useState([]);
  const [bidInfo, setBidInfo] = useState([]);
  const [hide, setHide] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

const handleAction = useCallback(
  async (actionType) => {
    const actionMap = {
      hideLoad: "hideLoad",
      unhideLoad: "unhideLoad",
      searchLoad: "searchLoad",
    };

    const action = actionMap[actionType] || "searchLoad";
    await Try(Action(action, from, to, data, setData, hide, setHide, types), action, setLoading);
  },
  [from, to, data, hide, types]
);


  useEffect(() => {
    handleAction("searchLoad");
  }, []);

  return (
    <>
      <Modal open={open} bidInfo={bidInfo} setOpen={setOpen} />
      <Grid container rowSpacing={1} spacing={12}>
        <Grid item xs={8}  sx={{ userSelect: "none" }}>
          <OptionsCard from={from} setFrom={setFrom} to={to} setTo={setTo} types={types} setTypes={setTypes} handleAction={handleAction} loading={loading} />
        </Grid>
        <Grid item xs={4}  sx={{ userSelect: "none" }}>
          <FiltersCard from={from} setFrom={setFrom} handleAction={handleAction} />
        </Grid>
        <Grid sx={{ marginTop: "25px" }} item xs={12}>
        <LoadsCard data={data} setOpen={setOpen} loading={loading} setLoading={setLoading} setBidInfo={setBidInfo} />
      </Grid>
      </Grid>

    </>
  );
};

export default Loads;
