import loads from "../api/loads";

const Action = async (actionType, from, to, data, setData, hide, setHide, types) => {
  const response = await loads.get(from, to, types);

  if (actionType === "hideLoad") {
    const oldIds = data.map((item) => item._id);
    const oldIds2 = [...hide, ...oldIds];
    setHide(oldIds2);

    const newData = response.filter((newItem) => !oldIds2.includes(newItem._id));
    setData(newData);
  } else if (actionType === "unhideLoad") {
    setHide([]);

    setData(response);
  } else {
    const newData = response.filter((newItem) => !hide.includes(newItem._id));
    setData(newData);
  }
};

export default Action;
