import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const Header = () => {
    return (
        <TableHead sx={{ userSelect: "none" }}>
          <TableRow>
            <TableCell >#</TableCell>
            <TableCell size="medium" >Unit</TableCell>
            <TableCell   >Name</TableCell>
            <TableCell >
            Date & Bid Notes
            </TableCell>
            <TableCell >
            Distance
            </TableCell>
            <TableCell >City & State</TableCell>
            <TableCell >Type</TableCell>
            <TableCell>Dims & Weight</TableCell>
            <TableCell>Load Dims <br /> Weight <br /> PCS</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
    );
};

export default Header;