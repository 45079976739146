import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useLocation } from "react-router-dom";
import { memo, useMemo } from "react";
import appNavs from "../../configs/navigations";
import MemoizedListItem from "./MemoizedListItem";
import decodeJWT from "../../utils/decodeJWT";

const drawerWidth = 240;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

function Sidebar({ open, setOpen }) {
	const { pathname } = useLocation();

	const handleDrawer = () => {
		setOpen(!open);
	};

	const Drawer = useMemo(
		() =>
			styled(MuiDrawer, {
				shouldForwardProp: (prop) => prop !== "open",
			})(({ theme, open }) => ({
				width: drawerWidth,
				flexShrink: 0,
				whiteSpace: "nowrap",
				boxSizing: "border-box",
				...(open && {
					...openedMixin(theme),
					"& .MuiDrawer-paper": openedMixin(theme),
				}),
				...(!open && {
					...closedMixin(theme),
					"& .MuiDrawer-paper": closedMixin(theme),
				}),
			})),
		// eslint-disable-next-line
		[open]
	);

	const DrawerHeader = useMemo(
		() =>
			styled("div")(({ theme }) => ({
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				padding: theme.spacing(0, 1),
				...theme.mixins.toolbar,
			})),
		[]
	);

	return (
		<Drawer
			sx={{ userSelect: "none" }}
			variant="permanent"
			open={open}
		>
			<DrawerHeader>
				<Typography
					variant="h6"
					noWrap
					component="div"
				>
					{!open ? (
						""
					) : (
						<div style={{ display: "flex", alignItems: "center", fontWeight: "bold", userSelect: "none" }}>
							<span>
								SPEED<span style={{ color: "#3498ff" }}> LOADERS</span>
							</span>
						</div>
					)}
				</Typography>
			</DrawerHeader>
			<Divider />
			<List>
				{appNavs.map((element) => {
					if (element.admin === true && (decodeJWT(global.token.token).payload.role === "admin") === false) return;
					return (
						<MemoizedListItem
							key={element.key}
							element={element}
							open={open}
							pathname={pathname}
						/>
					);
				})}
			</List>
			<Button
				color="inherit"
				size="large"
				onClick={handleDrawer}
				sx={{
					justifyContent: open ? "flex-end" : "center",
					marginTop: `auto`,
				}}
			>
				{!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
			</Button>
		</Drawer>
	);
}

export default memo(Sidebar);
