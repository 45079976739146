import { useState } from "react";
import checkBroker from "../../api/checkBroker";
import Try from "../../utils/tryPromise";
import SearchCard from "./Cards/Options";
import BrokersTable from "./Brokers Table/Table";
const CheckBroker = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [mc, setMc] = useState("");
  const [company, setCompany] = useState("");

  const handleSearch = async (event) => {
    event.preventDefault();
    Try(
      async () => {
        const response = await checkBroker.search({ mc, company });
        if (response) setData(response);
      },
      "checkBroker",
      setLoading
    );
  };

  return (
    <>
      <SearchCard setMc={setMc} setCompany={setCompany} loading={loading} handleSearch={handleSearch} />
      <BrokersTable data={data} />
    </>
  );
};

export default CheckBroker;
