import Grid  from "@mui/material/Grid";
import Typography  from "@mui/material/Typography";
import TextField  from "@mui/material/TextField";
import SelectComponent from "../Select/Select";
import { getStates } from "../../configs/states";
import { memo } from "react";
const CustomLocationField = ({data, changedFormValues, handleChange}) => {
    return (
        <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
        <Typography width={"5.5rem"} mt={1}>
          Location
        </Typography>
        <TextField
          onChange={(e) => {
            handleChange("City", e.target.value.trim());
            handleChange("State", changedFormValues.State ?? data.State);
          }}
          placeholder="City/Zip"
          fullWidth
          hiddenLabel
          defaultValue={data.City}
          size="small"
        />
        <SelectComponent
          title={"State"}
          options={getStates()}
          selectedOptions={changedFormValues.State ?? data.State}
          setSelectedOptions={(state) => {
            handleChange("State", state[0]);
          }}
        />
      </Grid>
    );
};

export default memo(CustomLocationField);