// DimensionTextFields.js
import React from "react";
import { TextField, Grid, Typography } from "@mui/material";

function DimensionTextFields({ data, handleChange, ...other }) {
  const [dims, setDims] = React.useState({
    x: parseInt(data.dims.split("x")[0]),
    y: parseInt(data.dims.split("x")[1]),
    z: parseInt(data.dims.split("x")[2]),
  });

  const handleDimChange = (dimension, value) => {
    setDims((prevDims) => {
      const newDims = { ...prevDims, [dimension]: parseInt(value) };
      handleChange("Dimensions", newDims);
      return newDims;
    });
  };

  return (
    <Grid display="flex" gap={2} justifyContent={"left"} item xs={12} {...other}>
      <Typography width={"4.5rem"} mt={1}>
        Dims
      </Typography>
      <TextField
        type="number"
        onChange={(e) => handleDimChange("x", e.target.value)}
        placeholder="L"
        hiddenLabel
        defaultValue={dims.x}
        size="small"
      />
      <TextField
        type="number"
        onChange={(e) => handleDimChange("y", e.target.value)}
        placeholder="W"
        hiddenLabel
        defaultValue={dims.y}
        size="small"
      />
      <TextField
        type="number"
        onChange={(e) => handleDimChange("z", e.target.value)}
        placeholder="H"
        hiddenLabel
        defaultValue={dims.z}
        size="small"
      />
    </Grid>
  );
}

export default DimensionTextFields;
