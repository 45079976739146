import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { memo } from "react";

const CustomNotesField = ({data, handleChange}) => {
  return (
    <>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
        <Typography width={"5rem"} mt={1}>
          Notes
        </Typography>
        <TextField
          onChange={(e) => handleChange("Notes", e.target.value.trim())}
          placeholder="None"
          fullWidth
          hiddenLabel
          defaultValue={data.notes}
          size="small"
        />
      </Grid>
      <Grid display="flex" gap={2} justifyContent={"left"} item xs={12}>
        <Typography width={"5rem"} mt={1}>
          Bid Notes
        </Typography>
        <TextField
          onChange={(e) => handleChange("bidNotes", e.target.value.trim())}
          placeholder="None"
          fullWidth
          hiddenLabel
          defaultValue={data.bidNotes}
          size="small"
        />
      </Grid>
    </>
  );
};

export default memo(CustomNotesField);
