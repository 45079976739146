const filterPatterns = [
	/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g, // Email pattern
	/\bemail(s)?\b/gi, // Words "email" and "emails"
	/\b\d{3}-\d{3}-\d{4}\b/g, // US phone number pattern
	/\b(?:rate(s)?|rate is \d+|\$\d+(\.\d{1,2})?)\b/gi, // Rate-related patt erns
	/\bphone(?: numbers?)?\b/gi, // Words "phone" and phrases "phone number" or "phone numbers"
	/\bEmail Bids only\b/gi, // Phrase "Email Bids only"
	/\bonly\b/gi, // Word "only"
	/\bBid(s)?\b/gi, // Words "Bid" and "Bids"
];

const sanitizeText = (text) => {
	let sanitizedText = text;
	for (const pattern of filterPatterns) {
		sanitizedText = sanitizedText.replace(pattern, " ");
	}
	return sanitizedText;
};

const load = (driver, load, broker) => {
	const notes = load.notes ? sanitizeText(load.notes) : "";
	const textToCopy = `
  Pick-up at: ${load.from.location} 
  Pick-up date (EST): ${load.from.time}
  
  Deliver to: ${load.to.location} 
  Delivery date (EST): ${load.to.time}
  
  Miles: ${load.miles}
  Pieces: ${load.pcs}
  Weight: ${load.weight}
  Dims: ${broker.dimensions}
  
  NOTES: ${notes}
  
  ${driver.distance} out`;

	navigator.clipboard.writeText(textToCopy);
};

const dispatch = (item, load) => {
	const textToCopy = `
${item.company === "gass" ? "GASS LOGISTICS CORP" : "LOGIMAX CORP"}
Unit: ${item.unit}
Rate: ${item.driverRate}$

${load.from.time}

${load.from.location}


${load.pcs} pcs    ${load.weight} Lbs


${load.to.time}

${load.to.location}


NOTE*** Location must be shared and on at all times. Failure to share location in Google maps will lead to rate reduction of 100$. When required Macropoint or other tracker tools must be accepted additionally.
***Freight piece count and/or weight may be different at pick up than provided in this dispatch. Considering stated van capacity, the broker books the entire van exclusively per trip and reserves the right to increase or decrease quantity of load at no additional cost.
  `;

	navigator.clipboard.writeText(textToCopy);
};

export default { load, dispatch };
