const getColorsByState = (state, colorsWithStates) => {
    const colors = [];
    for (const [color, { states }] of Object.entries(colorsWithStates)) {
      if (states.includes(state.trim())) {
        colors.push(color);
      }
    }
    return colors;
  };
  
  const groupUnitsByColor = (states, colorsWithStates) => {
    const unitsByColor = {};
    for (const color of Object.keys(colorsWithStates)) {
      unitsByColor[color] = {};
    }
  
    for (const [state, units] of Object.entries(states)) {
      for (const unit of units) {
        const colors = getColorsByState(state, colorsWithStates);
        for (const color of colors) {
          if (!unitsByColor[color][state]) {
            unitsByColor[color][state] = [];
          }
          unitsByColor[color][state].push(unit);
        }
      }
    }
  
    return unitsByColor;
  };
  
  export default groupUnitsByColor;
  