import axiosInstance from "../utils/axios";

const get = () => {
    return axiosInstance.get("/vehicles").then((response) => { return response?.data } )
};

const edit = (data) => {
    return axiosInstance.put("/vehicles", data).then((response) => { return response?.data } )
};

const remove = (data) => {
    return axiosInstance.delete("/vehicles", { data: {_id: data._id} }).then((response) => { return response?.data } )
};


const add = (Unit) => {
    return axiosInstance.post("/vehicles/add", { Unit }).then((response) => { return response?.data } )
};

export default {get, edit, remove, add};