import Box from "@mui/material/Box";
import { default as MCard } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Search from "../../../components/Search/Input";
import Button from "../Add Unit/Button";
import AddUnitModal from "../Add Unit/Modal";
import { useState } from "react";
import Grid from "@mui/material/Grid";

export default function SearchCard({ loading, setSearchTerm, handleAdd }) {
  const [open, setOpen] = useState(false);
  return (
    <Box
      sx={{
        marginTop: "1.5rem",
      }}
    >
      <AddUnitModal loading={loading} handleAdd={handleAdd} open={open} setOpen={setOpen} />
      <MCard variant="elevation">
        <CardContent>
          <Grid container rowSpacing={1} spacing={2} sx={{ userSelect: "none" }}>
            <Grid item xs={9}>
              <Search setSearchTerm={setSearchTerm} label={"Search for Unit"} icon={true} />
            </Grid>
            <Grid item xs={3}>
              <Button  setOpen={setOpen} loading={loading} />
            </Grid>
          </Grid>
        </CardContent>
      </MCard>
    </Box>
  );
}
