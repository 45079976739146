import React from "react";
import { Table, TableBody,  TableContainer,  Paper,   Card, CardContent, Typography } from "@mui/material";
import DriverRow from "./Row.jsx"
import Header from "./Header";



export default function DriverTable({ data: { nearbyDrivers: drivers, load, broker }, setRateModal, setRateData }) {
  const sortedDrivers = [...drivers].sort((a, b) => a.distance - b.distance);

  return (
    <Card variant="elevation" sx={{ minWidth: 500, minHeight: "100%", mt: "1rem" }}>
      <CardContent>
        <Typography align="left" variant="h5" gutterBottom>
          Nearby Drivers
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: "100%" }} size="small">
            <Header />
            <TableBody>
              {sortedDrivers.map((driver, index) => (
                <DriverRow key={driver._id} driver={driver} index={index} load={load} broker={broker} setRateModal={setRateModal} setRateData={setRateData} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
}
