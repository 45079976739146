import axios from "axios";

// (global.debug ? "http://localhost:4444" : "https://api.speedloaders.xyz/") ,
console.log(process.env.REACT_APP_DEBUG)
const axiosInstance = axios.create({
  baseURL: (process.env.REACT_APP_DEBUG === "true" ? "http://localhost:4444" : "https://api.speedloaders.xyz/") ,
});

global.controllers = {
  "/": [],
  "/loads": [],
  "/vehicles": [],
  "/coloredStates": [],
  "/checkBroker": [],
  "/closestUnits": [],
  "/findLoad": [],
  "/history": [],
  "/login": [],
  "/invoices": [],
  "/payslip": [],
  "/users": [],
};

axiosInstance.interceptors.request.use((config) => {
  const controller = new AbortController();
  global.controllers[window.location.pathname].push(controller);
  config.headers.Authorization = window.localStorage.getItem("token");
  config.signal = controller.signal;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === "ERR_CANCELED") {
      return Promise.resolve();
    }
    if (error.response && error.response.status === 403) {
      global.token.setToken("");
      return Promise.resolve();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
