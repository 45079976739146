import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

export default function CompanyTabs({value, handleChange}) {


  return (
    <Tabs value={value} onChange={handleChange}>
      <Tab label="GASS" value="gass" />
      <Tab label="LOGIMAX" value="lmux" />
    </Tabs>
  );
}