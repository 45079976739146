import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";

const Buttons = ({ handleAction, loading }) => {
  return (
    <>
      <Button
        disabled={loading}
        size="large"
        onClick={() => handleAction("searchLoad")}
        variant="outlined"
        startIcon={<SearchIcon />}
      >
        Search
      </Button>
      <Button
        disabled={loading}
        size="large"
        onClick={() => handleAction("hideLoad")}
        color="error"
        variant="outlined"
        startIcon={<VisibilityOffIcon />}
      >
        Hide
      </Button>
      <Button
        disabled={loading}
        size="large"
        onClick={() => handleAction("unhideLoad")}
        color="secondary"
        variant="outlined"
        startIcon={<VisibilityIcon />}
      >
        UNHIDE
      </Button>
    </>
  );
};

export default Buttons;
