import { Card, CardContent, Typography, Grid, TextField } from "@mui/material";

const renderElement = (label, value, width, index) => (
  <Grid key={index} item xs={width}>
      <TextField inputProps={{readOnly: true}} size="small" fullWidth={ width === true } label={label} defaultValue={value} variant="outlined" />
  </Grid>
);

const Load = ({ data: { load, broker } }) => (
  <Card sx={{ minWidth: "100%", minHeight: "100%", mt: "1rem" }}>
    <CardContent>
      <Typography align="left" variant="h5" gutterBottom>
        Load Information
      </Typography>
      <Grid container rowSpacing={2} spacing={-50} mt={1} sx={{ userSelect: "none" }}>
        {[
          ["Pick-up At", load?.from?.location, 3],
          ["Pick-up Date (EST)", load?.from?.time, 3],
          ["Deliver to", load?.to?.location, 3],
          ["Delivery Date (EST)", load?.to?.time, 3],
          ["Type", load?.vehicle, 4],
          ["Rate", load?.rate, 4],
          ["Dimensions", broker?.dimensions, 4],
          ["Pieces", load?.pcs, 3],
          ["Weight", load?.weight, 3],
          ["Miles", load?.miles, 3],
          ["Order", load?.orderNo, 3],
          ["Hazardous", broker?.hazMaterial, 3],
          ["Dock Level", broker?.dockLevel, 3],
          ["Fast Load", broker?.csaLoad, 3],
          ["Stackable", broker?.stackable, 3],
          ["Notes", load?.notes, true],
        ].map(([label, value, width], index) => renderElement(label, value, width, index))}
      </Grid>
    </CardContent>
  </Card>
);

export default Load;
