import { useState, forwardRef, memo } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Slide } from "@mui/material";
import Calculator from "./Calculator";
import Bid from "./Bid";
import CloseIcon from "@mui/icons-material/Close";
import loads from "../../../api/loads";
import PublishIcon from '@mui/icons-material/Publish';
import Try from "../../../utils/tryPromise";
const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle({ children, onClose, ...other }) {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose && (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function RateModal({ open, setOpen, data }) {
  const [bidData, setBidData] = useState({
    gass: {
      rate: null,
      notes: null,
    },
    lmux: {
      rate: null,
      notes: null,
    },
  });

  const handleClose = () => setOpen(false);

  return (
    <div>
      <BootstrapDialog
        PaperProps={{
          variant: "outlined",
          elevation: 0,
        }}
        fullWidth
        maxWidth="md"
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Bid for {data ? data.unit : ""} Unit
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Calculator />
          <Bid setBidData={setBidData} />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            disabled={!bidData.gass.rate && !bidData.lmux.rate ? true : false}
            variant="outlined"
            color="success"
            startIcon={<PublishIcon/>}
            onClick={async () => {
              handleClose();
              Try(loads.send({ ...bidData, ...data }), "bid");
              setBidData({
                gass: {
                  rate: null,
                  notes: null,
                },
                lmux: {
                  rate: null,
                  notes: null,
                },
              });
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default memo(RateModal);
